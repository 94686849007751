import 'bootstrap/dist/css/bootstrap.min.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import './homepage.css'; // Ensure this includes your custom styles
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import topvideo from "../../assets/homepage/newvideo.mp4";
import mobilevideo from "../../assets/homepage/mobilevideo.mp4";
import React, { useState, useEffect, useRef } from "react";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const TopSlider = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 650px)');
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);


  const handleCorporateClick = () => {
    navigate("/categories/corporate");
  };
  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };
  return (
    <div className="carousel-wrapper">
      {isMobile ? (
        <div>
        <video
          className="page-video-tag"
          ref={videoRef}
          controls={false}
          preload="true"
          loop={true}
          autoPlay={true}
          playsInline
          muted
        >
          <source src={mobilevideo} type="video/mp4" />
        </video>
        <button className="video-btn-slider" onClick={toggleMute}>
            {isMuted ? (
              <VolumeOffIcon
                style={{ fontSize: "18px", color: "white", cursor: "pointer" }}
              />
            ) : (
              <VolumeUpIcon
                style={{ fontSize: "18px", color: "white", cursor: "pointer" }}
              />
            )}
          </button>
        </div>
      ) : (
        <div>
        <video
          className="page-video-tag"
          ref={videoRef}
          controls={false}
          preload="true"
          loop={true}
          autoPlay={true}
          playsInline
          muted
        >
          <source src={topvideo} type="video/mp4" />
        </video>
        <button className="video-btn-slider" onClick={toggleMute}>
            {isMuted ? (
              <VolumeOffIcon
                style={{ fontSize: "18px", color: "white", cursor: "pointer" }}
              />
            ) : (
              <VolumeUpIcon
                style={{ fontSize: "18px", color: "white", cursor: "pointer" }}
              />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default TopSlider;
