import React, { useState, useEffect, useRef } from "react";
import "../community/communitySlider.css";
import Slider from "react-slick";
import CommaImg from "../../assets/community/comma.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Element, scroller } from "react-scroll";
import { useMediaQuery } from "@mui/material";

import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

function CommunitySlider({ cardHeight }) {

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [playingVideo, setPlayingVideo] = useState(null);

  const sliderRef1 = useRef(null);
  const sliderRef2 = useRef(null);

  const isXs = useMediaQuery("(max-width:600px)");
  const isSm = useMediaQuery("(max-width:960px)");
  const isMd = useMediaQuery("(max-width:1280px)");

  const next = () => {
    if (sliderRef2.current) {
      sliderRef2.current.slickNext();
    }
  };
  const previous = () => {
    if (sliderRef2.current) {
      sliderRef2.current.slickPrev();
    }
  };

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
  });

  const [show, setShow] = useState(false);

  const updateScreenSize = () => {
    setScreenSize({
      width: window.innerWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  useEffect(() => {
    if (screenSize.width <= 600) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [screenSize]);

  const data = [
    {
      id: 1,
      url: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/The+Devilslayer+who+makes+a+Legendary+Comeback+-+Abbas+_+Devils+Circuit+2023-24.mp4",
      title:
        "The Devilslayer who makes a Legendary Comeback - Abbas | Maruti Suzuki Arena Devils Circuit 2023-24",
      yturl: "https://www.youtube.com/embed/D3N1gg-ardY?si=3Mk502DgCbQn4COp",
    },
    {
      id: 2,
      url: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/The+Devilslayer+who+thrives+on+experiences+_+Varghese+_++Devils+Circuit+2023-24.mp4",
      title:
        "The Devilslayer who thrives on experiences | Varghese | Maruti Suzuki Arena Devils Circuit 2023-24",
      yturl: "https://www.youtube.com/embed/9-VwWG6BKpE?si=tAtlaaXedyIp2rIz",
    },
    {
      id: 3,
      url: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/videoplayback.mp4",
      title:
        "The Devilslayer Who is Invincible - Glen | Maruti Suzuki Arena Devils Circuit 2023-24",
      yturl: "https://www.youtube.com/embed/NjOPcb7tSAI?si=shjo_awTnxwMxCs4",
    },
    {
      id: 4,
      url: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Tripat+Singh%2C+the+78-Year-Old+Fitness+Enthusiast+Who+Conquered+the+Devils+Circuit!+_+Heroes+of+DC.mp4",
      title:
        "Tripat Singh, the 78-Year-Old Fitness Enthusiast Who Conquered the Maruti Suzuki Arena Devils Circuit! | Heroes of DC",
      yturl: "https://www.youtube.com/embed/swYD4KccOvA?si=m4jMaQvg3aBXg99U",
    },
    {
      id: 5,
      url: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/The+Inspiring+Specially-Abled+Devilslayer+_+Ajay+Lalwani+_+Heroes+of+DC.mp4",
      title:
        "The Inspiring Specially-Abled Devilslayer | Ajay Lalwani | Heroes of DC",
      yturl: "https://www.youtube.com/embed/2ugeuQdkLVM?si=Lgo6ovNeZj25e-12",
    },
    {
      id: 6,
      url: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Our+Idea+of+Inspiration_+Nithin+Ghorpade+-+A+5-Time+Iron+Man+Finisher+%26+Devilslayer+_+Heroes+of+DC.mp4",
      title:
        "Our Idea of Inspiration: Nithin Ghorpade - A 5-Time Iron Man Finisher & Devilslayer | Heroes of DC",
      yturl: "https://www.youtube.com/embed/Na1O2ky2_0c?si=D7CEyO24AXcC9881",
    },
    {
      id: 7,
      url: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/Milton+Simons_+From+Donating+a+Kidney+to+Conquering+the+Devils+Circuit+_+Heroes+of+DC.mp4",
      title:
        "Milton Simons: From Donating a Kidney to Conquering the Maruti Suzuki Arena Devils Circuit | Heroes of DC",
      yturl: "https://www.youtube.com/embed/NgAf9ROJV7g?si=87cviVeW6LGtJZ_b",
    },
  ];

  const getYouTubeVideoId = (url) => {
    const match = url.match(/embed\/([a-zA-Z0-9_-]+)/);
    return match ? match[1] : null;
  };

  useEffect(() => {
    setNav1(sliderRef1.current);
    setNav2(sliderRef2.current);
  }, []);

  const handleThumbnailClick = (itemId) => {
    scroller.scrollTo("video", {
      duration: 0,
      delay: 0,
      smooth: "easeInOutQuart",
    });

    if (sliderRef1.current) {
      sliderRef1.current.slickGoTo(itemId - 1);
      handleVideoPlay(itemId - 1);
    }
  };

  const handleVideoPlay = (index) => {
    if (playingVideo !== null && playingVideo !== index) {
      document.getElementById(`video-${playingVideo}`).pause();
    }
    setPlayingVideo(index);
    document.getElementById(`video-${index}`).play();
  };

  const responsiveSettings = [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
      },
    },
  ];

  return (
    <div className="slider-container">
      <div className="community_slider_main_div">
        <p className="community_slider_main_heading">
          Extraordinary Devilslayers
        </p>
        <div className="community_slider_comma_img_div">
          <img src={CommaImg} alt="img" />
        </div>
        <Slider
          asNavFor={nav2}
          ref={sliderRef1}
          arrows={false}
          fade={true}
          beforeChange={(current, next) => setCurrentSlide(next)}
        >
          {data.map((item, index) => (
            <Element name="video" key={item.id}>
              <div className="community_slider_top_img_div">
                <video
                  id={`video-${index}`}
                  className="community_slider_video"
                  height={cardHeight}
                  src={item.url}
                  controls={true}
                  preload="true"
                  loop={true}
                  autoPlay={index === currentSlide}
                  playsInline
                  muted
                  onPlay={() => handleVideoPlay(index)}
                />
                <p className="community_slider_main_title">{item.title}</p>
              </div>
            </Element>
          ))}
        </Slider>
      </div>
     {/* {show ?  */}
      {/* <div className="community_second_slide_div">
        <div className="community_slider_arrow_left_div">
          <button className="button" onClick={previous}>
            <ArrowBackIosNewIcon fontSize="20px" />
          </button>
        </div>

        <Slider
          asNavFor={nav1}
          ref={sliderRef2}
          slidesToShow={4}
          swipeToSlide={true}
          focusOnSelect={true}
          arrows={false}
          responsive={responsiveSettings}
          className="community_slider_btns_div"
        >
          {data.map((item, index) => {
            const videoId = getYouTubeVideoId(item.yturl);
            const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
            return (
              <div
                key={item.id}
                className="community_slider_thumbnail_div"
                onClick={() => handleThumbnailClick(item.id)}
              >
                <img
                  className="community_slider_thumbnail_div_img"
                  src={thumbnailUrl}
                  alt={`Thumbnail for video ${item.id}`}
                />
                <p>
                  {currentSlide !== index ? (
                    <PlayCircleIcon
                      fontSize="20px"
                      className="community_play_btn"
                    />
                  ) : (
                    <PauseCircleFilledIcon
                      fontSize="20px"
                      className="community_play_btn"
                    />
                  )}
                </p>
              </div>
            );
          })}
        </Slider>

        <div className="community_slider_arrow_right_div">
          <button className="button" onClick={next}>
            <ArrowForwardIosIcon fontSize="20px" />
          </button>
        </div>
      </div>   */}

      <div className="community_second_slide_div">
        <div className="community_slider_btns_div">
          {data.map((item, index) => {
            const videoId = getYouTubeVideoId(item.yturl);
            const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
            return (
              <div
                key={item.id}
                className="community_slider_thumbnail_div"
                onClick={() => handleThumbnailClick(item.id)}
              >
                <img
                  className="community_slider_thumbnail_div_img"
                  src={thumbnailUrl}
                  alt={`Thumbnail for video ${item.id}`}
                />
              </div>
            );
          })}
        </div>
      </div> 
    </div>
  );
}

export default CommunitySlider;