import React from "react";
import "./citypage.css";
import Playground from "../homepage/playground";
import band from "../../assets/homepage/newband.png";
import { Link } from "react-router-dom";

const cityPageData = {
  cityData: [
    {
      cityName: "Ahmedabad",
      eventName: "Maruti Suzuki Arena Devils Circuit Ahmedabad",
      eventDate: "November 10, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/1_3_11zon.webp",
      venue: "Adani Shantigram Township, Ahmedabad",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/166/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/166/register`,
      showJunior: false,
    },
    {
      cityName: "Indore",
      eventName: "Maruti Suzuki Arena Devils Circuit Indore",
      eventDate: "September 29, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/2_1_11zon.webp",
      venue:
        "Omaxe Supercorridor QQRM+85P, Indore, Budhaniya, Madhya Pradesh 453112",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/165/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/165/register`,
      showJunior: false,
    },
    {
      cityName: "Pune",
      eventName: "Maruti Suzuki Arena Devils Circuit Pune",
      eventDate: "November 24, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/3_2_11zon.webp",
      venue: "Hiranandani-Krisala Township, Darumbre, Pune 410506",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/164/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/164/register`,
      showJunior: false,
    },
    {
      cityName: "Mumbai",
      eventName: "Maruti Suzuki Arena Devils Circuit Mumbai",
      eventDate: "October 20, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/4_4_11zon.webp",
      venue:
        "Raheja district, Sales Gallery, Juinagar, MIDC Industrial Area, Juinagar, Navi Mumbai, Maharashtra 400705",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/163/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/163/register`,
      showJunior: false,
    },
    {
      cityName: "Hyderabad",
      eventName: "Maruti Suzuki Arena Devils Circuit Hyderabad",
      eventDate: "January 5, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/5_10_11zon.webp",
      venue: "Hyderabad",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/162/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/162/register`,
      showJunior: false,
    },
    {
      cityName: "Bengaluru",
      eventName: "Maruti Suzuki Arena Devils Circuit Bengaluru",
      eventDate: "December 15, 2024",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/6_6_11zon.webp",
      venue: "Bengaluru",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/161/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/161/register`,
      showJunior: true,
    },
    {
      cityName: "Kochi",
      eventName: "Maruti Suzuki Arena Devils Circuit Kochi",
      eventDate: "January 19, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/7_7_11zon.webp",
      venue: "Kochi",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/160/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/160/register`,
      showJunior: false,
    },
    {
      cityName: "Chennai",
      eventName: "Maruti Suzuki Arena Devils Circuit Chennai",
      eventDate: "February 2, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/8_5_11zon.webp",
      venue: "Chennai",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/159/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/159/register`,
      showJunior: false,
    },
    {
      cityName: "Mohali",
      eventName: "Maruti Suzuki Arena Devils Circuit Mohali",
      eventDate: "February 16, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/9_8_11zon.webp",
      venue:
        "Omaxe New Chandigarh, Near Omaxe The Lake, Baddi Kurali Road, New Chandigarh, Mullanpur, Mohali, SAS Nagar, Punjab, 140901",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/158/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/158/register`,
      showJunior: false,
    },
    {
      cityName: "Delhi",
      eventName: "Maruti Suzuki Arena Devils Circuit Delhi NCR",
      eventDate: "March 9, 2025",
      backgroundImage:
        "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/10_9_11zon.webp",
      venue: "Worldstreet By Omaxe Sec 79, Faridabad, Haryana- 121006",
      buttonLink:
        sessionStorage.getItem("utm_source") !== "null"
          ? `https://getmybib.com/157/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
          : `https://getmybib.com/157/register`,
      showJunior: true,
    },
  ],
};

const CityPage = (props) => {
  const city = cityPageData.cityData.find(
    (city) => city.cityName === props.view
  );

  if (!city) {
    return <div>City not found</div>;
  }

  const para =
    "Gear up with a branded Jockey t-shirt and headband, stay energized with drinks, hydrate at stations, and proudly wear your finisher medal. Dominate the challenge, emerge victorious.";

  return (
    <div>
      <div className="cityheading">{city.eventName}</div>
      <div className="citytopbanner">
        <div class="image_wrapper_city">
          <img src={city.backgroundImage} className="citybannerimage" alt="" />
          <div class="overlay"></div>
        </div>
        {/* <img src={city.backgroundImage} className="citybannerimage" /> */}
        <div className="aboutcity">
          <p className="cityLocation">
            <strong>Venue : </strong> {city.venue}
          </p>
          <p className="cityEventDate">
            <strong>Date :</strong> {city.eventDate}
          </p>
          <p className="cityname">
            <strong>Categories :</strong> Competitive, non competitive, master
            {city.showJunior ? ", Junior" : ""}
          </p>
          <Link
            to={city.buttonLink}
            style={{ textDecoration: "none" }}
            className="cityregisterbutton"
          >
            Register Now{" "}
          </Link>
        </div>
      </div>
      <div className="citydescription">
        <p className="citypara1"></p>
        <p className="citypara2">
          Maruti Suzuki Arena Devils Circuit is the Rockstar of Running Events:
          it is Asia's biggest Obstacle Race and the only running event in India
          to boost a community of over two million runners! But we believe our
          runners can do much more than conquer a rugged stretch of land,
          therefore, we have peppered our five-kilometre track with 15 crazy,
          fun obstacles to conquer before you reach the Finish Line isn't that
          cool
        </p>
        <p>
          When you join our Tribe of DevilSlayers, you will find so many runners
          who share your life's story. On the circuit, you will find strangers
          help you cross obstacles, and by your own virtue of goodness, you will
          help others out. Because, our end goal is the same: "We Help Each
          Other Become the Best Version of Ourselves." You will see the
          difference as soon as you are at the Start Line and the following
          excuses will cease to exist
        </p>
      </div>
      <Playground showJunior={city.showJunior} category={true} />
      <div className="band_img_div_comp">
        <img src={band} alt="band" loading="lazy" />
      </div>
      <div className="obstaclesection">
        <div className="headingandbutton">
          <div className="obstacleheading">
            <span className="cityobstaclenumber">15</span>{" "}
            <span className="cityobstacletext">obstacles</span>
          </div>
          <Link
            to={"/obstacles"}
            style={{ textDecoration: "none" }}
            className="seeallbutton"
          >
            SEE ALL
          </Link>
        </div>
        <div className="obstaclecardouterdiv">
          <div className="obstaclecard">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/ertg.webp" />
            <div className="cardobstaclename">Commando Crawl</div>
          </div>
          <div className="obstaclecard">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Rectangle+484+(1).webp" />
            <div className="cardobstaclename">Rope Climb</div>
          </div>
          <div className="obstaclecard">
            <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/sefref.webp" />
            <div className="cardobstaclename">The Summit</div>
          </div>
          <Link
            to={"/obstacles"}
            style={{ textDecoration: "none" }}
            className="seeallbuttonmobile"
          >
            SEE ALL
          </Link>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default CityPage;
