import React from "react";
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import "./corporate.css";
function Banner() {
  return (
    <>
    
    <CCarousel controls={false} interval={2000}>
      <CCarouselItem>
        <div className="carousel-item-content">
          {/* <h1>Adrenaline Rush</h1>
          <p>Ignite excitement and energy with thrilling obstacle course challenges.</p> */}
          <CImage
            className="carousel-image"
            src={"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/1440x434-corporate-6674159907451_3_11zon.webp"}
            alt="slide 1"
          />
          
        </div>
      </CCarouselItem>
      
      <CCarouselItem>
        <div className="carousel-item-content">
          {/* <h1>Unity Building</h1>
          <p>Strengthen team cohesion through collaborative problem-solving and support.</p> */}
          <CImage
            className="carousel-image"
            src={"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/1440x434-corporate-2-6674159b7b6ea_2_11zon.webp"}
            alt="slide 2"
          />
          
           {/* <div>
            <p>Unity Buildingt</p>
            <p>Strengthen team cohesion through collaborative problem-solving and support.</p>
          </div> */}

        </div>
      </CCarouselItem>
      <CCarouselItem>
        <div className="carousel-item-content">
          {/* <h1>Adrenaline Rush</h1>
          <p>Ignite excitement and energy with thrilling obstacle course challenges.</p> */}
         <CImage
            className="carousel-image"
            src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/1440x434_Corporate+3.jpg"
            alt="slide 2"
          />
          
        </div>
      </CCarouselItem>
    </CCarousel>
    </>
    
  );
}

export default Banner;
