import "./competitive.css";
import obstacle from "../../assets/obstacle.png";
import FindRace from "../common/findRaceFooter/findrace";
import band from "../../assets/homepage/newband.png";

function Competitive() {
  return (
    <>
      <div className="competitiveContainer">
        <img
          src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/comp_11zon.webp"
          alt="comp"
        />
        <div>
          <div>
            <h1>Competitive Wave A:</h1>
            <p>
              The first wave of participants is limited to a maximum of 100
              participants who get to be the first ones to experience the
              course. The limited number of participants ensures minimum traffic
              on the course, a fresh ground to run on, and an opportunity to
              time their personal best.
            </p>
          </div>
          <div>
            <h1>Competitive Wave B:</h1>
            <p>
              Although this wave is secondary, it still offers the participants
              a chance to find their podium finish and claim the prizes.
            </p>
          </div>
          <div>
            <h1>Prizes to be Won City Specific:</h1>
            <table className="table">
              <tr>
                <th>Winner</th>
                <td>Winners Flag + Amazon Voucher worth INR 30000</td>
              </tr>
              <tr>
                <th>1st Runners Up</th>
                <td>Winners Flag + Amazon Voucher worth INR 15000</td>
              </tr>
              <tr>
                <th>2nd Runners Up</th>
                <td>Winners Flag + Amazon Voucher worth INR 5000</td>
              </tr>
            </table>
          </div>
          <div>
            <h1>Masters Category:</h1>
            <table className="table">
              <tr>
                <th>Winner</th>
                <td>Winners Flag + Amazon Voucher worth 5000 INR</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div className="band_img_div_comp">
        <img src={band} alt="band" loading="lazy" />
      </div>
      {/* <div className="backgroundBanner" style={{ backgroundImage: `url(${obstacle})` }}>
            </div> */}
      <div className="season">
        <div>
          <h1>Season Specific:</h1>
          <p>
            At Maruti Suzuki Arena Devils Circuit, we give the winners of the
            season the biggest prize in India for a competition such as this.
            The top Male and Female finishers have the opportunity to win a
            brand new Maruti Suzuki Swift, in addition to cash prizes!
          </p>
          <table className="seasonTableOne">
            <tr>
              <th>Winner Season 2024-25 ( Male)</th>
              <td>Maruti Suzuki Swift + Cash Prize of INR 200000</td>
            </tr>
            <tr>
              <th>Winner Season 2024-25 ( Female)</th>
              <td>Maruti Suzuki Swift + Cash Prize of INR 100000</td>
            </tr>
            <tr>
              <th>Runners Up - (Male) </th>
              <td>Cash prize of INR 100000</td>
            </tr>
            <tr>
              <th>Runners Up - (Female) </th>
              <td>Cash prize of INR 50000</td>
            </tr>
            <tr>
              <th>2nd Runners Up- (Male) </th>
              <td>Cash prize of INR 50000</td>
            </tr>
            <tr>
              <th>2nd Runners Up- (Female) </th>
              <td>Cash prize of INR 25000</td>
            </tr>
          </table>
        </div>
        <div>
          <h1>Criteria to win the Season Prizes:</h1>
          <p>
            In order to be eligible for the Season Prizes, participants MUST
            compete in atleast 6 competitive races across the Season. They may
            choose to come in more races to give themselves an opportunity of
            earning more points. The top 10 finishers in male and female
            categories are awarded points according to their position on the
            leaderboard. These points keep getting added after each race. The
            final tally of these points at the end of the season determines the
            winner.
          </p>
          <p>Points awarded to the Top 10 Positions </p>
          <table className="seasonTable">
            <tr>
              <th>1st Position</th>
              <td>20 Points</td>
            </tr>
            <tr>
              <th>2nd Position</th>
              <td>18 Points</td>
            </tr>
            <tr>
              <th>3rd Position</th>
              <td>16 Points</td>
            </tr>
            <tr>
              <th>4th Position</th>
              <td>14 Points</td>
            </tr>
            <tr>
              <th>5th Position</th>
              <td>12 Points</td>
            </tr>
            <tr>
              <th>6th Position</th>
              <td>10 Points</td>
            </tr>
            <tr>
              <th>7th Position</th>
              <td>8 Points</td>
            </tr>
            <tr>
              <th>8th Position</th>
              <td>6 Points</td>
            </tr>
            <tr>
              <th>9th Position</th>
              <td>4 Points</td>
            </tr>
            <tr>
              <th>10th Position</th>
              <td>2 Points</td>
            </tr>
          </table>
          <div className="desc_text">
            Bonus Points: Participants who make it to the podium three times
            consecutively receive an additional three bonus points.
          </div>
        </div>
      </div>

      <div
        className="backgroundBanner"
        style={{ backgroundImage: `url(${obstacle})` }}
      ></div>
      <div className="competitiveContainer">
        <div>
          <div>
            <h1>RULES FOR PODIUM FINISH:</h1>
          </div>
          <div>
            <h2>Male Category: </h2>
            <p>
              Must complete ALL 15 obstacles and the 5kms race, all in under 50
              minutes.
            </p>
          </div>
          <div>
            <h2>Female Category:</h2>
            <p>
              Must complete a minimum of 13 obstacles and the 5kms race, all in
              under 70 minutes.
            </p>
          </div>
          <div>
            <h2>Masters Category (Male participants over the age of 40):</h2>
            <p>
              Must complete ALL 15 obstacles and the 5kms race, all in under 60
              minutes.
            </p>
          </div>
        </div>
      </div>
      <FindRace
        heading="Unleashing the Power of Resistance!"
        para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance."
        btn_link={"/find-your-race"}
        btn_text="Find Your Race"
      />
    </>
  );
}
export default Competitive;
