import React, { useState } from "react";
import { data } from "../../../src/assets/cateories/data";
import PageHead from "../common/pageHead/pageHead";
import "./marketing.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AutoScrollCarousel from "./AutoScrollCarousel";
import * as Yup from "yup";
import axios from "axios"; // Ensure axios is imported
import { toast, ToastContainer } from "react-toastify"; // Ensure react-toastify is imported
import CircularProgress from "@mui/material/CircularProgress"; // Ensure @mui/material is imported

const initialValues = {
  fullName: "",
  Email: "",
  Mobile: "",
  Company: "",
  Address: "",
  Message: "",
};

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Name should be at least 2 characters")
    .max(100, "Name should be less than 100 characters")
    .required("Name is required"),
  Email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  Mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
    .required("Mobile Number is required"),
  Company: Yup.string()
    .min(2, "Company should be at least 2 characters")
    .max(100, "Company should be less than 100 characters"),
  Address: Yup.string()
    .min(2, "Address should be at least 2 characters")
    .max(100, "Address should be less than 100 characters"),
  Message: Yup.string()
    .min(2, "Message should be at least 2 characters")
    .max(500, "Message should be less than 500 characters"),
});

function MarketingAssociation() {
  const [spinState, setSpinState] = useState(false);

  const handleSubmit = async (values) => {
    setSpinState(true);

    try {
      await axios.post("https://submit-form.com/CSpljFgXC", values);
      setSpinState(false);
      toast.success("Message submitted successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      setSpinState(false);
      console.error("Error submitting form:", error);
      toast.warn("Something went wrong, Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const preventArrowKeyIncrement = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }
  };
  return (
    <div>
      <PageHead
        page_name={data.marketing[0].heading}
        text_1={data.marketing[0].marketing}
        // media={data.marketing[0].image_url}
        // media_type="image"
      />
      <AutoScrollCarousel />
      <div className="marketingContainer">
        <p>{data.marketing[0].para1}</p>
        <p>
          {data.marketing[0].para2} <span>{data.marketing[0].email} </span>
          {data.marketing[0].para3}
        </p>
      </div>
      <div className="marketing_form_main_div">
        <div className="marketing_form_div">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="marketing_form">
                  <div className="marketing_field_box">
                    <label htmlFor="fullName">Name*</label>
                    <Field
                      type="text"
                      id="fullName"
                      name="fullName"
                      className="marketing_field"
                    />
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="marketing_invalid_feedback"
                    />
                  </div>

                  <div className="marketing_field_box">
                    <label htmlFor="Email">Email*</label>
                    <Field
                      type="email"
                      id="Email"
                      name="Email"
                      className="marketing_field"
                    />
                    <ErrorMessage
                      name="Email"
                      component="div"
                      className="marketing_invalid_feedback"
                    />
                  </div>

                  <div className="marketing_field_box">
                    <label htmlFor="Mobile">Mobile*</label>
                    <Field
                      type="number"
                      id="Mobile"
                      name="Mobile"
                      className="marketing_field"
                      onKeyDown={preventArrowKeyIncrement}
                    />
                    <ErrorMessage
                      name="Mobile"
                      component="div"
                      className="marketing_invalid_feedback"
                    />
                  </div>

                  <div className="marketing_field_box">
                    <label htmlFor="Company">Company</label>
                    <Field
                      type="text"
                      id="Company"
                      name="Company"
                      className="marketing_field"
                    />
                    <ErrorMessage
                      name="Company"
                      component="div"
                      className="marketing_invalid_feedback"
                    />
                  </div>

                  <div className="marketing_field_box">
                    <label htmlFor="Address">Address</label>
                    <Field
                      type="text"
                      id="Address"
                      name="Address"
                      className="marketing_field"
                    />
                    <ErrorMessage
                      name="Address"
                      component="div"
                      className="marketing_invalid_feedback"
                    />
                  </div>

                  <div className="marketing_text_area">
                    <label htmlFor="Message">Leave us a message</label>
                    <Field
                      as="textarea"
                      type="text"
                      id="Message"
                      name="Message"
                      className="marketing_text_area_field"
                    />
                    <ErrorMessage
                      name="Message"
                      component="div"
                      className="marketing_invalid_feedback"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="marketing_join_btn_div"
                  style={{
                    cursor: !spinState ? "pointer" : "none",
                  }}
                >
                  <div className="marketing_join_btn_inner_div">
                    {spinState ? (
                      <CircularProgress
                        className="custom-progress"
                        style={{ color: "white" }}
                        size={25}
                        thickness={3}
                      />
                    ) : (
                      <span>Submit</span>
                    )}
                  </div>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default MarketingAssociation;
