import React from "react";
import { Link } from "react-router-dom";
import "./homepage.css";
import TshirtVideo from "../../assets/homepage/tshirt.mp4";

const BannerVideo = () => {
  const TshirtVideo = "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/T-SHIRT3DIFFRENT(1).mp4";
  const handleKnowMoreClick = (e) => {
    e.preventDefault();
    // Handle 'Know More' button click event, e.g., navigate to a specific route
    console.log("Know More button clicked!");
    // Perform navigation using React Router
  };
  const videoURL =
    "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/T-SHIRT+3+DIFFRENT+(1).mp4";
  return (
    <>
      <div className="banner-video-container">
        <video
          className="banner-video"
          controls={false}
          preload="true"
          loop={true}
          autoPlay={true}
          muted
          playsInline
        >
          <source src={videoURL} type="video/mp4" />
        </video>
        <Link
          to="/learn-more"
          className="know-more-button"
          onClick={handleKnowMoreClick}
        >
          Coming Soon
        </Link>
      </div>
      <div className="mobile-banner-container">
        <div className="mobile-banner-content">
          <h1 className="banner-heading">Accessorize with Attitude</h1>
          <h2 className="banner-subheading">Ignite Your Style 
with Maruti Suzuki Arena Devils Circuit Merchandise! </h2>
          <button className="banner-button">Coming Soon</button>
        </div>
      </div>
      <div>
      <video
          className="banner-video-mobile"
          controls={false}
          preload="true"
          loop={true}
          autoPlay={true}
          muted
          playsInline
        >
          <source src={TshirtVideo} type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default BannerVideo;
