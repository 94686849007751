import React from "react";
import "../proud/proud.css";
import ProudImg from "../../../assets/homepage/proudImg.svg";

const Proud = () => {
  return (
    <>
      <div className="proud_main_div">
        <div className="proud_inner_div">
        <img src={ProudImg} alt="img" />
        <p>
          We are proud to have Maruti Suzuki Arena as the official sponsor of
          the Devils Circuit. Their commitment to excellence and innovation
          aligns perfectly with the spirit of our events, where determination
          and performance drive success.
        </p>
        </div>
      </div>
    </>
  );
};

export default Proud;
