import React from "react";
import "./obstacleList.css";
import obstacleImage from "../../assets/underline.svg"; 
import doorwalk from "../../assets/doorwalk.webp";

const obstacles = [
  { id: 1, name: "Commando Crawl", desc: "Description for obstacle 1", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(4)_11zon.webp" },
  { id: 2, name: "Height of Hell", desc: "Description for obstacle 2", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(5)_11zon.webp" },
  { id: 3, name: "The Summit", desc: "Description for obstacle 3", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(11)_11zon.webp" },
  { id: 4, name: "Step Up", desc: "Description for obstacle 4", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(12)_11zon.webp" },
  { id: 5, name: "Slip Down", desc: "Description for obstacle 5", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(6)_11zon.webp" },
  { id: 6, name: "Log Leap", desc: "Description for obstacle 6", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(7)_11zon.webp" },
  { id: 7, name: "The High Rise", desc: "Description for obstacle 7", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(1)_11zon.webp" },
  { id: 8, name: "Hang Time Hustle", desc: "Description for obstacle 8", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(9)_11zon.webp" },
  { id: 9, name: "Portal Path", desc: "Description for obstacle 9", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(2)_11zon.webp" },
  { id: 10, name: "Wall Climb", desc: "Description for obstacle 10", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(8)_11zon.webp" },
  { id: 11, name: "The Roller", desc: "Description for obstacle 11", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(10)_11zon.webp" },
  { id: 12, name: "Murder by Mud", desc: "Description for obstacle 12", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(2)+(1)_11zon.webp" },
  { id: 13, name: "Monkey Crawl", desc: "Description for obstacle 13", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(3)_11zon.webp" },
  { id: 14, name: "Back Stack", desc: "Description for obstacle 14", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(13)_11zon.webp" },
  { id: 15, name: "Brain Freeze", desc: "Description for obstacle 15", img: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/420X236+(14)_11zon.webp" }
];

const ObstacleList = () => {
  return (
    <div className="outerObstacleListDiv">
      <div className="obstacleHeading">
        <span className="yearText">2024-25</span> Obstacles
      </div>
      <div className="obstacleImageContainer">
        <img src={obstacleImage} alt="Underline" className="headingImage" />
      </div>
      <div className="obstacleCardContainer">
        {obstacles.map(obstacle => (
          <div key={obstacle.id} className="obstacleCard">
            <img src={obstacle.img} alt={obstacle.name} className="obstacleImage" />
            <h3 className="obstacleName">{obstacle.name}</h3>
            {/* <p className="obstacleDesc">{obstacle.desc}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ObstacleList;
