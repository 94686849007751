import React from "react";
import { useNavigate } from "react-router-dom";
import './pagenotfound.css';
import backgroundImage from "../../assets/pagenotfound/pagebackground.png"; 
import centerImage from "../../assets/pagenotfound/textnotfound.png"; 

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };

  return (
    <div className="page-not-found">
      <img src={backgroundImage} alt="Background" className="background-svg" />
      <div className="center-content">
         <img src={centerImage} alt="Center" className="center-svg" />
         <p className="pagenotfounddesc">
         Oops! Page Not Found
         </p>
        <button onClick={handleNavigateHome} className="home-button"> 
          Home Page
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;
