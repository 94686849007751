import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import './movingBanner.css';
import "slick-carousel/slick/slick-theme.css";

// Importing all images dynamically
const images = [
    require('../../../assets/movingbanner/adobe.png'),
    require('../../../assets/movingbanner/chola.png'),
    require('../../../assets/movingbanner/gale.png'),
    require('../../../assets/movingbanner/global.png'),
    require('../../../assets/movingbanner/heubach.png'),
    require('../../../assets/movingbanner/infosys.png'),
    require('../../../assets/movingbanner/makeMyTrip.png'),
    require('../../../assets/movingbanner/nagarro.png'),
    require('../../../assets/movingbanner/optum.png'),
    require('../../../assets/movingbanner/sony.png'),
    require('../../../assets/movingbanner/wtw.png'),
    require('../../../assets/movingbanner/zerodha.png'),
    require('../../../assets/movingbanner/zoho.png'),
    require('../../../assets/movingbanner/zomato.png')
];

function MovingBanner({ showBorder}) {

    return (

        <div className={`movingBanner ${showBorder ? 'showBorderTop' : ''}`}>
            <div class="sliderBanner">
                <div className="slide-track">
                    {images.map((image, index) => (
                        <div className="slide" key={index}>
                            <img src={image} height="100" width="250" alt="" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default MovingBanner;
