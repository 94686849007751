// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PageNotFound.css */
.page-not-found {
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .background-svg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .center-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 1;
  }
  
  .pagenotfounddesc {
    color: white;
    font-size: 36px;
  }
  
  .center-svg {
    max-width: 100%;
    height: 300px;
  }
  
  .home-button {
    background-color: #ba1e24;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: auto;
    margin: 10px auto 0 auto;
    /* border-radius: 4px; */
  }
  
  @media screen and (max-width: 650px) {
    .center-svg {
      max-width: 100%;
      height: 150px;
    }
  
    .pagenotfounddesc {
      font-size: 16px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pagenotfound/pagenotfound.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,WAAW;IACX,wBAAwB;IACxB,wBAAwB;EAC1B;;EAEA;IACE;MACE,eAAe;MACf,aAAa;IACf;;IAEA;MACE,eAAe;IACjB;EACF","sourcesContent":["/* PageNotFound.css */\n.page-not-found {\n    position: relative;\n    height: 100vh;\n    width: 100vw;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    overflow: hidden;\n  }\n  \n  .background-svg {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n    z-index: -1;\n  }\n  \n  .center-content {\n    display: flex;\n    flex-direction: column;\n    text-align: center;\n    z-index: 1;\n  }\n  \n  .pagenotfounddesc {\n    color: white;\n    font-size: 36px;\n  }\n  \n  .center-svg {\n    max-width: 100%;\n    height: 300px;\n  }\n  \n  .home-button {\n    background-color: #ba1e24;\n    color: white;\n    border: none;\n    padding: 10px 20px;\n    font-size: 16px;\n    cursor: pointer;\n    width: auto;\n    margin: 10px auto 0 auto;\n    /* border-radius: 4px; */\n  }\n  \n  @media screen and (max-width: 650px) {\n    .center-svg {\n      max-width: 100%;\n      height: 150px;\n    }\n  \n    .pagenotfounddesc {\n      font-size: 16px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
