import React, { useState } from "react";
import Select from "react-select";
import "../train/train.css";
import PageHead from "../common/pageHead/pageHead";
import BannerImg from "../../assets/train/img3.webp";
import CardImg from "../../assets/train/cardImg.webp";
import NearMeOutlinedIcon from '@mui/icons-material/NearMeOutlined';
import { Padding } from "@mui/icons-material";

const Train = () => {
  const gyms = [
    {
      id: 1,
      name: "Gold Gym",
      address: "Ground Floor, 1/1A Camac St, Kolkata, West Bengal 700016",
      city: "Kolkata",
      lat: 22.5451,
      lng: 88.3536,
      contactNumber: "+91 33 3054 6400",
      email: "info@goldsgym.in",
    },
    {
      id: 2,
      name: "Talwalkars Gym",
      address:
        "Plot No 108, 109, 110, Kavuri Hills, Phase 1, Madhapur, Hyderabad, Telangana 500033",
      city: "Hyderabad",
      lat: 17.4504,
      lng: 78.3915,
      contactNumber: "+91 40 2355 9826",
      email: "info@talwalkars.net",
    },
    {
      id: 3,
      name: "Anytime Fitness",
      address:
        "Level 2, Food Court, Star Mall, NH-8, Sector 30, Gurgaon, Haryana 122001",
      city: "Gurgaon",
      lat: 28.4625,
      lng: 77.0266,
      contactNumber: "+91 99900 62000",
      email: "info@anytimefitness.in",
    },
    {
      id: 4,
      name: "Snap Fitness",
      address: "Sector 22, Gurugram, Haryana 122015",
      city: "Gurugram",
      lat: 28.4486,
      lng: 77.0377,
      contactNumber: "+91 124 430 9251",
      email: "info@snapfitnessindia.com",
    },
    {
      id: 5,
      name: "Fitness First",
      address:
        "DLF Phase IV, Super Mart 1, Near Galeria Market, Gurugram, Haryana 122009",
      city: "Gurugram",
      lat: 28.4816,
      lng: 77.0845,
      contactNumber: "+91 124 421 2313",
      email: "info@fitnessfirst.net.in",
    },
    {
      id: 6,
      name: "Cult.fit",
      address:
        "Plot No 10, Venkatanarayana Rd, MRC Nagar, Raja Annamalai Puram, Chennai, Tamil Nadu 600028",
      city: "Chennai",
      lat: 13.0359,
      lng: 80.2577,
      contactNumber: "+91 95661 23456",
      email: "care@cult.fit",
    },
    {
      id: 7,
      name: "Fitness One",
      address:
        "2nd Floor, Mantri Square, Sampige Rd, Malleshwaram, Bengaluru, Karnataka 560003",
      city: "Bengaluru",
      lat: 12.9915,
      lng: 77.5703,
      contactNumber: "+91 80 2266 3390",
      email: "info@fitnessoneclub.com",
    },
    {
      id: 8,
      name: "Mickey Mehta Wellness",
      address:
        "702, Krishna, Above Seasons Hotel, 7th Floor, Juhu Tara Rd, Mumbai, Maharashtra 400049",
      city: "Mumbai",
      lat: 19.1032,
      lng: 72.8277,
      contactNumber: "+91 22 2660 3660",
      email: "info@mickeymehtahbf.com",
    },
    {
      id: 9,
      name: "Bodyline Sports Club",
      address: "A-52/2, Mahipalpur Ext, NH-8, New Delhi, Delhi 110037",
      city: "New Delhi",
      lat: 28.5451,
      lng: 77.1243,
      contactNumber: "+91 11 2678 1637",
      email: "info@bodylinesportsclub.com",
    },
    {
      id: 10,
      name: "Life Fitness Gym",
      address:
        "3rd Floor, PM Tower, Opp. Reliance Fresh, Durgapur, West Bengal 713216",
      city: "Durgapur",
      lat: 23.5204,
      lng: 87.3119,
      contactNumber: "+91 343 258 5698",
      email: "info@lifefitnessgym.in",
    },
  ];

  const uniqueCities = [...new Set(gyms.map((gym) => gym.city))];
  const cityOptions = uniqueCities.map((city) => ({ value: city, label: city }));

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [tooltipContent, setTooltipContent] = useState("");

  const filteredGyms = gyms.filter((gym) => {
    const matchesName = gym.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCity = selectedCity ? gym.city === selectedCity.value : true;
    return matchesName && matchesCity;
  });

  const customStyles = {
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '2px 0',
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    control: (provided) => ({
      ...provided,
      color: "white",
      borderRadius: "6px",
      border: "none",
      boxShadow: "none",
      "&:focus": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      color: "#000",
      border: "none",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#000",
      },
    }),
  };

  const handleCardClick = (gym) => {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${gym.lat},${gym.lng}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleMarkerClick = (gym) => {
    setTooltipContent(`${gym.name}, ${gym.city}, ${gym.email}, ${gym.contactNumber}`);
  };

  const truncateAddress = (address, maxLength = 65) => {
    if (!address) return '';
    return address.length > maxLength ? address.slice(0, maxLength) + '...' : address;
  };

  return (
    <div>
      <PageHead
        page_name="Train"
        page_head="Train"
        text_1="Our partner gyms are your gateway to conquering the Maruti Suzuki Arena Devils Circuit Race and to help you move forward in your fitness journey. With tailored 
training programs and expert guidance, you will reach your fitness goals and beyond. Push your limits, feel the thrill, and emerge stronger."
        media={BannerImg}
        media_type="image"
      />

      <div className="train_cards_main_div"> 
        <div className="train_cards_header_div">
          <p>Find Gym in your city</p>
          <div className="train_cards_header_underline"></div>
        </div>

        <div className="train_filters">
          <div className="train_search_div">
            <label htmlFor="search_name">Search</label>
            <input
              type="text"
              id="search_name"
              placeholder="Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-bar"
            />
          </div>

          <div className="train_search_div">
            <label htmlFor="city">Select City</label>
            <Select
              id="city"
              options={cityOptions}
              value={selectedCity}
              onChange={setSelectedCity}
              placeholder="Select city"
              isClearable
              className="train_city_select"
              styles={customStyles}
              isSearchable={false}
            />
          </div>
        </div>

        <div className="train_cards_div">
          <div className="train_cards_inner_div">
            {filteredGyms.map((gym) => (
              <div key={gym.id} className="train_card">
                <div className="train_card_img_div">
                  <img src={CardImg} alt="img" />
                </div>
                <div className="train_card_info_div">
                  <p className="train_card_name">{gym.name}</p>
                  <p className="train_card_address">{truncateAddress(gym.address)} <span onClick={() => handleCardClick(gym)}> direction <NearMeOutlinedIcon className="train_card_address_icon"/></span> </p>
                  <p className="train_card_email"><strong>Email:</strong> {gym.email}</p>
                  <p className="train_card_contact"><strong>Contact:</strong> {gym.contactNumber}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default Train;
