import "./junior.css";
import FindYourRaceCard from "../findYourRace/FindYourRaceCard";
import FindRace from "../common/findRaceFooter/findrace";
const data = [
  {
    id: 1,
    city: "Bengaluru",
    date: "December 15, 2024 | Sunday",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/1-668cc615c8405.webp",
    knowMoreLink: "city-page/Bengaluru",
    link:
      sessionStorage.getItem("utm_source") !== "null"
        ? `https://getmybib.com/161/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
        : `https://getmybib.com/161/register`,
  },
  {
    id: 1,
    city: "Delhi NCR",
    date: "March 9, 2025 | Sunday",
    image:
      "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/2-668cc614c7658.webp",
    knowMoreLink: "city-page/Delhi",
    link:
      sessionStorage.getItem("utm_source") !== "null"
        ? `https://getmybib.com/157/register?utm_source=${sessionStorage.getItem(
            "utm_source"
          )}&utm_medium=${sessionStorage.getItem(
            "utm_medium"
          )}&utm_campaign=${sessionStorage.getItem(
            "utm_campaign"
          )}&utm_id=${sessionStorage.getItem("utm_id")}`
        : `https://getmybib.com/157/register`,
  },
];
function Junior() {
  return (
    <>
      <div className="juniorContainer">
        <img
          src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/junioor-66741f255ca35.webp"
          alt="non_comp"
        />
        <div>
          <p>You asked and we delivered!</p>
          <p>
            Our junior devilslayers now have a race specially designed for
            themselves. It includes running 3 kms and overcoming 10 bespoke
            obstacles- complete with mud, water and ice!
          </p>
          <p>
            For now this race will have only the non competitive option and be
            available on event days at the Bengaluru and Delhi venues.
          </p>
        </div>
        <div className="findRace">
          <div>
            <h1>Find Your Race</h1>
            <div>
              <FindYourRaceCard data={data} />
            </div>
          </div>
        </div>
      </div>
      <FindRace
        heading="What's Included!"
        para="Gear up with a branded Jockey t-shirt and headband, stay energized with drinks, hydrate at stations, and proudly wear your finisher medal. Dominate the challenge, emerge victorious."
        btn_link={"/whatsIncluded"}
        btn_text="Know More"
        imageUrl="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit_finalimages/Whats%2Bincluded+(2).webp"
      />
    </>
  );
}
export default Junior;
