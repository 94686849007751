import React from "react";
import PageHead from "../common/pageHead/pageHead";
import FindRace from "../common/findRaceFooter/findrace";
import ObstacleList from "./ObstacleList";

const Obstacles = () => {
  const para =
    "Maruti Suzuki Arena Devils Circuit challenges participants with a series of exhilarating obstacles designed to test their strength, agility, and determination. From scaling towering walls to crawling through muddy trenches, each obstacle presents a unique challenge that pushes participants to their limits.";

  return (
    <>
      <PageHead
        page_name={"THRILLING OBSTACLES"}
        page_head={"Thrilling Obstacles Await!"}
        text_1={para}
        media={
          "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Obstacles+CUT+(1)_2.mp4"
        }
        media_type={"video"}
      />
      <ObstacleList/>
<FindRace heading="Unleashing the Power of Resistance!" para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance."  btn_text="Find Your Race" />
    </>
  );
};

export default Obstacles;
