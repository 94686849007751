import React from "react";
import { CCarousel, CCarouselItem, CImage } from "@coreui/react";
import "@coreui/coreui/dist/css/coreui.min.css";

const AutoScrollCarousel = () => {
  return (
    <CCarousel controls={false} interval={1000}>
      <CCarouselItem>
        <CImage
          className="crousal_marketing_image"
          src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/RED%2BBULL%2B(1)_3_11zon.webp"
          alt="Slide 1"
        />
      </CCarouselItem>
      <CCarouselItem>
        <CImage
          className="crousal_marketing_image"
          src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Apollo_2_1_11zon.webp"
          alt="Slide 2"
        />
      </CCarouselItem>
      <CCarouselItem>
        <CImage
          className="crousal_marketing_image"
          src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/BUNKER_2_11zon.webp"
          alt="Slide 3"
        />
      </CCarouselItem>
    </CCarousel>
  );
};

export default AutoScrollCarousel;
