import React, { useState, useEffect } from "react";
import "../../components/findYourRace/findYourRaceCard.css";
import {useNavigate } from "react-router-dom";

const FindYourRaceCard = ({ data }) => {
  const navigate = useNavigate() 

  return (
    <>
      {data.map((item) => {
        return (
          <div className="findyourrace_card">
            <div className="findyourrace_card_img_div">
              <img src={item.image} alt="image" />
            </div>
            <div className="findyourrace_card_info_div">
              <p className="findyourrace_card_title">{item.city}</p>
              <p className="findyourrace_card_date">{item.date}</p>
              <div className="findyourrace_card_btns">
              <button className="findyourrace_card_know_more" onClick={() => navigate(`/${item.knowMoreLink}`)}>
                  Know More
                </button>
                <a href={item.link}>
                  <button className="findyourrace_card_register">
                    {" "}
                    Register Now
                  </button>
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FindYourRaceCard;
