import React, { useEffect, useState } from "react";
import "./winnerList.css";
import { Link } from "react-router-dom";
import CategoryIcon from '../../assets/Category.svg'; // Hardcoded import for Category icon
import WinnerIcon from '../../assets/Winner.svg'; // Hardcoded import for Winner icon

const winnersData = [
  {
    id: 1,
    year: "2023-24",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/RAD10363.JPG",
    name: "Aseem Khan",
    category: "Male competitive"
  },
  {
    id: 2,
    year: "2023-24 & 2022-23",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/Zahabiya+Merchant+2022-23.jpg",
    name: "Zahabiya Merchant",
    category: "Female competitive"
  },
  {
    id: 3,
    year: "2022-23 & 2018-19",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/lc-mwinner-18-19-o6hrhjyvmkc0d9otorazhcisepciw2tc87z9ahjpv0.jpg",
    name: "Sukhchain Singh",
    category: "Male competitive"
  },
  {
    id: 5,
    year: "2019-20",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/lucas-kemp-2019-20-667526288a362.webp",
    name: "Lucas Kempe",
    category: "Male competitive"
  },
  {
    id: 6,
    year: "2019-20 & 2018-19",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/diksha-kapoor-2019-20-6675262b78d02.webp",
    name: "Diksha Kapoor",
    category: "Female competitive"
  },
  {
    id: 9,
    year: "2017-18",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/lc-mwinner-17-18-o6hrhh5d2285efsx5833rv8emjqf8zi57u0sunnwdo.jpg",
    name: "Ajith CT",
    category: "Male competitive"
  },
  {
    id: 10,
    year: "2017-18",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/lc-fwinner-17-18-o6hrhi378w9fq1rjzqhqcczv7xlsgolvjyoabxmi7g.jpg",
    name: "Sanjana George",
    category: "Female competitive"
  },
  {
    id: 11,
    year: "2016-17",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/lc-mwinner-16-17-o6hrhg7iv86v2tuaapoh7dgy15v21aeevpdbddpajw.jpg",
    name: "Sanjay Negi",
    category: "Male competitive"
  },
  {
    id: 12,
    year: "2016-17",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/lc-fwinner-16-17-o6hrhi378w9fq1rjzqhqcczv7xlsgolvjyoabxmi7g.jpg",
    name: "Ravinderpreet Kaur",
    category: "Female competitive"
  },
  {
    id: 13,
    year: "2015-16",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/lc-mwinner-15-16-o6hrhh5d2285efsx5833rv8emjqf8zi57u0sunnwdo_edited.jpg",
    name: "Balbir Kumar",
    category: "Male competitive"
  },
  {
    id: 14,
    year: "2015-16",
    image: "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/devil_circuit/lc-fwinner-15-16-o6hrhh5d2285efsx5833rv8emjqf8zi57u0sunnwdo.jpg",
    name: "Sneha Desu",
    category: "Female competitive"
  }
];



const WinnerList = () => {
  const speed = 80000;

  const handleHover = (e) => {
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      section.style.animationPlayState = "paused";
    });
  };

  const handleMouseLeave = (e) => {
    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      section.style.animationPlayState = "running";
    });
  };

  return (
    <div className="outerMostLeaderboardDiv">
      <p className="leaderboardHeading">
        <span className="highlight">#Devilslayers</span> who made history
      </p>
      <div className="leaderboardSubHeader">
        <p className="leaderboardSubHeading">Through The Years</p>
      </div>
      
      <div className="OuterleaderboardDiv">
        <div className="InnerleaderboardDiv">
          <section style={{ "--speed": `${speed}ms` }}>
            {winnersData.map((winner) => (
              <div
                key={winner.id}
                className="leaderboardImage"
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
              >
                <img src={winner.image} alt={winner.name} />
                <p className="winnerName">{winner.name}</p>
                <p className="winnerName">
                  <img src={CategoryIcon} alt="Category Icon" className="customWinnerIcon" />
                  {winner.category}
                </p>
                <p className="winnerYear">
                  <img src={WinnerIcon} alt="Winner Icon" className="customWinnerIcon" />
                  {winner.year}
                </p>
              </div>
            ))}
          </section>
          <section style={{ "--speed": `${speed}ms` }}>
            {winnersData.map((winner) => (
              <div
                key={winner.id}
                className="leaderboardImage"
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
              >
                <img src={winner.image} alt={winner.name} />
                <p className="winnerName">{winner.name}</p>
                <p className="winnerName">
                  <img src={CategoryIcon} alt="Category Icon" className="customWinnerIcon" />
                  {winner.category}
                </p>
                <p className="winnerYear">
                  <img src={WinnerIcon} alt="Winner Icon" className="customWinnerIcon" />
                  {winner.year}
                </p>
              </div>
            ))}
          </section>
        </div>
      </div>
    </div>
  );
};

export default WinnerList;
