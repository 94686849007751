import React, { useState } from "react";
import "../franchise/franchise.css";
import PageHead from "../common/pageHead/pageHead";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios"; // Ensure axios is imported
import CircularProgress from "@mui/material/CircularProgress";
import { toast, ToastContainer } from "react-toastify"; // Ensure react-toastify is imported

const initialValues = {
  fullName: "",
  Email: "",
  Mobile: "",
  Company: "",
  Address: "",
  Message: "",
};


const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Name should be at least 2 characters")
    .max(100, "Name should be less than 100 characters")
    .required("Name is required"),
  Email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  Mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile Number must be exactly 10 digits")
    .required("Mobile Number is required"),
  Company: Yup.string()
    .min(2, "Company should be at least 2 characters")
    .max(100, "Company should be less than 100 characters"),
  Address: Yup.string()
    .min(2, "Address should be at least 2 characters")
    .max(100, "Address should be less than 100 characters"),
  Message: Yup.string()
    .min(2, "Message should be at least 2 characters")
    .max(500, "Message should be less than 500 characters"),
});

const Franchise = () => {
  const [spinState, setSpinState] = useState(false);

  const handleSubmit = async (values) => {
    setSpinState(true);

    try {
      await axios.post("https://submit-form.com/reXu8Nvnq", values);
      setSpinState(false);
      toast.success("Message submitted successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } catch (error) {
      setSpinState(false);
      console.error("Error submitting form:", error);
      toast.warn("Something went wrong, Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  const para ="The rockstar of running events! A place where there is inclusivity and celebration in abundance. We're not your typical Obstacle Course Race. We welcome everyone, not just the fittest, and celebrate the extraordinary achievements of ordinary people."
  const para2 = "The village area hosts an unforgettable after-party, keeping the excitement alive long after participants cross the finish line."
  return (
    <>
      <div className="franchise_wrapper">
        <div className="franchise_main_div">
          <PageHead
            page_name={"Franchise Opportunity"}
            text_1={para}
            text_2={para2}
            media={
              "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/franchise_11zon.webp"
            }
            media_type={"image"}
          />

          <div className="frnachise_info_main_div">
            <div className="frnachise_info_div">
              <p className="frnachise_info_p1">
              With a 5 km-long race track interspersed with 15 military-style obstacles, this is truly a race for everyone!

              </p>
              <p className="frnachise_info_p2">
              And the village area that hosts the after-party? Well, when your participants finish on a high, We want to keep them engaged for much longer.
              </p>

              <ul>
                <li>
                Are you someone with experience successfully executing large-scale events?
                </li>
                <li>
                  If you are someone who understands how to market a brand.
                </li>
                <li>
                  If you are someone who has experience of managing mass
                  participation events.
                </li>
                <li>
                  If you wish to host this unique competition in your geography
                  and want to understand the licensing opportunity, get in touch
                  with us at <span>franchise@devilscircuit.com</span>
                </li>
              </ul>
              <p className="frnachise_info_p3">
                We are looking for partners across the world, all geographies are
                welcome!.
              </p>
            </div>
          </div>
        </div>
        <div className="franchise_form_main_div">
        <div className="franchise_form_div">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values);
            }}
          >
            {({ isSubmitting, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="franchise_form">
                  <div className="franchise_field_box">
                    <label htmlFor="fullName">Name*</label>
                    <Field
                      type="text"
                      id="fullName"
                      name="fullName"
                      className="franchise_field"
                    />
                    <ErrorMessage
                      name="fullName"
                      component="div"
                      className="franchise_invalid_feedback"
                    />
                  </div>

                  <div className="franchise_field_box">
                    <label htmlFor="Email">Email*</label>
                    <Field
                      type="email"
                      id="Email"
                      name="Email"
                      className="franchise_field"
                    />
                    <ErrorMessage
                      name="Email"
                      component="div"
                      className="franchise_invalid_feedback"
                    />
                  </div>

                  <div className="franchise_field_box">
                    <label htmlFor="Mobile">Mobile*</label>
                    <Field
                      type="number"
                      id="Mobile"
                      name="Mobile"
                      className="franchise_field"
                    />
                    <ErrorMessage
                      name="Mobile"
                      component="div"
                      className="franchise_invalid_feedback"
                    />
                  </div>

                  <div className="franchise_field_box">
                    <label htmlFor="Company">Company</label>
                    <Field
                      type="text"
                      id="Company"
                      name="Company"
                      className="franchise_field"
                    />
                    <ErrorMessage
                      name="Company"
                      component="div"
                      className="franchise_invalid_feedback"
                    />
                  </div>

                  <div className="franchise_field_box">
                    <label htmlFor="Address">City</label>
                    <Field
                      type="text"
                      id="Address"
                      name="Address"
                      className="franchise_field"
                    />
                    <ErrorMessage
                      name="Address"
                      component="div"
                      className="franchise_invalid_feedback"
                    />
                  </div>

                  <div className="franchise_text_area">
                    <label htmlFor="Message">Leave us a message</label>
                    <Field
                      as="textarea"
                      type="text"
                      id="Message"
                      name="Message"
                      className="franchise_text_area_field"
                    />
                    <ErrorMessage
                      name="Message"
                      component="div"
                      className="franchise_invalid_feedback"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="franchise_join_btn_div"
                  style={{
                    cursor: !spinState ? "pointer" : "none",
                  }}
                >
                  <div className="franchise_join_btn_inner_div">
                    {spinState ? (
                      <CircularProgress
                        className="custom-progress"
                        style={{ color: "white" }}
                        size={25}
                        thickness={3}
                      />
                    ) : (
                      <span>Submit</span>
                    )}
                  </div>
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer/>

      </div>
    </>
  );
};

export default Franchise;
