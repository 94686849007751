import { useEffect } from "react";
const Facebook = () => {
  useEffect(() => {
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return; // Return if fbq is already defined
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    ); // Immediately invoke the function
    // Initialize Facebook Pixel
    if (window.fbq) {
      window.fbq("init", "128211730878887"); // Replace with your actual Pixel ID
      window.fbq("track", "PageView"); // Track page view
    } else {
      console.error("fbq is not defined");
    }
  }, []);
  return null; // Return null if you don't want to render anything
};
export default Facebook;