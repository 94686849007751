import React from "react";
import "../footer/footer.css";
import Logo from "../../../assets/logo/newWhiteLogo.svg";
import FacebookIcon from "../../../assets/social/fb.svg";
import InstaIcon from "../../../assets/social/insta.svg";
import YTIcon from "../../../assets/social/yt.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
    <div className="footer_wrapper">
      <div className="footer_main_div">
        <div className="footer_top_container">
          <div className="footer_left_container">
            <div className="footer_logo_div">
              <img src={Logo} alt="logo" />
            </div>
            <div className="footer_social_div">
              <p>Follow Us:</p>
              <div className="footer_social_icons">
                <a href="https://www.facebook.com/DevilsCircuit">
                  <img src={FacebookIcon} alt="icon" />
                </a>
                <a href="https://www.instagram.com/devilscircuit/">
                  <img src={InstaIcon} alt="icon" />
                </a>
                <a href="https://www.youtube.com/channel/UCeZTF0GplLDNV7m5JFnrBMg">
                  <img src={YTIcon} alt="icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer_right_container">
            <div className="footer_right_sub_container">
              <div className="footer_card">
                <p>Race</p>
                <ul>
                  <Link to="/find-your-race" style={{ textDecoration: "none" }}><li className="footer_li">Find Your Race</li></Link>
                  <Link to="/categories" style={{ textDecoration: "none" }}><li className="footer_li">Categories</li></Link>
                  <Link to="/corporate" style={{ textDecoration: "none" }}><li className="footer_li">Corporate Participation</li></Link>
                  <Link to="/whatsIncluded" style={{ textDecoration: "none" }}><li className="footer_li">What's Included</li></Link>
                </ul>
              </div>
              <div className="footer_card">
                <p>Devilslayer</p>
                <ul>
                  <Link to="/leaderboard" style={{ textDecoration: "none" }}><li className="footer_li">Leaderboard</li></Link>
                  <Link to="/photos" style={{ textDecoration: "none" }}><li className="footer_li">Photographs</li></Link>
                   {/* <Link to="/" style={{ textDecoration: "none" }}> */}
                  {/* <li className="footer_li"> 
                    Shop Now &nbsp;
                    <span className="comingSoon">(Coming Soon)</span>

                    </li> */}
                    {/* </Link> */}
                    <Link to="/village" style={{ textDecoration: "none" }}><li className="footer_li">Village</li></Link>
                    <Link to="/community" style={{ textDecoration: "none" }}><li className="footer_li">Community</li></Link>
                </ul>
              </div>
            </div>

            <div className="footer_right_sub_container">
              <div className="footer_card">
                <p>Partner</p>
                <ul>
                  <Link to="/marketing_association" style={{ textDecoration: "none" }}><li className="footer_li">Marketing Association</li></Link>
                  <Link to="/franchise" style={{ textDecoration: "none" }}><li className="footer_li">Franchise Opportunity</li></Link>
                  <Link to="/gym_partnership" style={{ textDecoration: "none" }}><li className="footer_li">Gym Partnership</li></Link>
                  <Link to="/venue_partnership" style={{ textDecoration: "none" }}><li className="footer_li">Venue Partnership</li></Link>
                </ul>
              </div>
              <div className="footer_card">
                <p>Support</p>
                <ul>
                  <Link to="/about-us" style={{ textDecoration: "none" }}><li className="footer_li">About Us</li></Link>
                  <Link to="/contact-us" style={{ textDecoration: "none" }}><li className="footer_li">Contact Us</li></Link>
                  <Link to="/faq" style={{ textDecoration: "none" }}><li className="footer_li">FAQ</li></Link>
                </ul>
              </div>
            </div>
          </div>
        </div> 
        
      </div>
    </div>
    <div className="footer_bottom_container_wrapper">
    <div className="footer_bottom_container">
      <div className="footer_bottom_copyright_div">
        <p>©Spectacom Global Pvt Ltd, INC 2020</p>
      </div>
      <div className="footer_bottom_privacy_policy_div">
      <Link to="/terms-and-condition" style={{ textDecoration: "none",color:"white" }}><p>Terms & Conditions</p></Link>
      <Link to="/privacy-policy" style={{ textDecoration: "none",color:"white" }}><p>Privacy Policy</p></Link>
      </div>
    </div>
    </div>
    </>
  );
};

export default Footer;