import "./privacypolicy.css";

const PrivactPolicy = () => {
    return <div>
        <div className="headingPrivacy">
        Privacy Policy
                </div>
                {/* <div className="privacyImage">
                <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/PP+(1).jpg"/>
                </div>
                <div className="privacy-divider"></div> */}
                <div className="mainTextContainer">
                {/* <p className="innerheading">PRIVACY POLICY</p> */}
                <p>Volano Entertainment Private Limited is a company registered in India under the Companies Act 1956 having its registered office at House No. 148 T/F Block No. 1 Subhash Nagar, West Delhi, New Delhi – 110027, India and its corporate office at 809 Udyog Vihar, Phase V, Gurgaon 122016 (referred to as "VEPL", "we" or "us") in this Privacy Policy ("Policy"). We at VEPL including our affiliates and subsidiaries, are strongly committed to protecting the privacy of your Personal Data (as defined hereinafter). The words "you" or "your" or "User" as used herein, refer to all individuals and/or entities browsing, accessing or using the mobile application and/or the VEPL website www.devilscircuit.com (collectively referred to as the "VEPL Platform"), for understanding our Services (as defined hereinafter) and obtaining information regarding the same or any other purpose.</p>
<p>This Policy explains our collection, retention, use, processing, disclosure and protection of the Personal Data that you may provide to us when communicating with us, or otherwise interacting with us including the use of the VEPL Platform.</p>
<p>This Policy is intended to inform the User the following:</p>
<p>(a) The kinds of Personal Data we may gather during your visit to the VEPL Platform;</p>
<p>(b) Why we gather your information;</p>
<p>(c) What we use your Personal Data for;</p>
<p>(d) When we might disclose your Personal Data; and</p>
<p>(e) How you can manage your Personal Data.</p>
<p>This Policy constitutes a legal agreement between you, the User of the VEPL Platform, and us. By communicating with us, or otherwise interacting with VEPL, including the use of the VEPL Platform, you are hereby confirming that you are over the age of 18 (eighteen) and are not a person with a disability; and providing us your unconditional, informed, express, specific and voluntary consent and allowing us to use, collect, access, process, store, disclose, transfer and protect your Personal Data in accordance with this Policy and for the purposes stated herein. In the event any of the matters detailed in this Policy is unclear to you, you are requested to discontinue any further use of the VEPL Platform/Services (as defined hereinafter) and write to info@devilscircuit.com, to first obtain the relevant clarifications.</p>
<p>This Policy shall be read in conjunction with the General T&amp;Cs, and any other terms and conditions published on the VEPL Platform.</p>
<p><strong>DEFINITIONS</strong></p>
<p>For the purposes of this Policy, the following terms shall have the meaning set forth below:</p>
<p>"Data Protection Law" shall mean any data protection, data security or privacy law, including, without limitation, the Information Technology Act, 2000 including the rules thereunder ("IT Act and Rules"), the Digital Personal Data Protection Act, 2023, and any other applicable laws governing Personal Data, communication-related data protection laws, data security or privacy laws.</p>
<p>"Personal Data" shall mean any personally identifiable information relating to an identified or identifiable individual, including data that identifies an individual or that could be used to identify, locate, track, or contact an individual. Personal Data includes both directly identifiable information, such as name, identification number or unique job title, and indirectly information such as date of birth, unique mobile or wearable device identifier, information that could be used to identify a household, telephone number, key-coded data or online identifier, such as Internet Protocol (IP) addresses, and includes any data that constitutes "personal data" or "sensitive personal data or information" under the IT Act and Rules, or similar terms under other Data Protection Law. However, Personal Data excludes information that is made public by an individual (such as their social media profiles, or posts on public parts of public platforms online) and/or any other information made public as required under law.</p>
<p>"Sensitive Personal Data or Information" with respect to a person shall mean such personal information which consists of information relating to:</p>
<p>• Biometric information;<br/>• Financial information such as bank account or credit card or debit card or other payment instrument details;<br/>• Medical records and history;<br/>• Password;<br/>• Physical, physiological and mental health conditions;<br/>• Sexual orientation;<br/>• Any detail relating to the above clauses as provided to us for providing the Services; and<br/>• Any information received under above clauses by us for processing, stored or processed under lawful contract or otherwise.</p>
<p>"Services" shall mean and include (without limitation) organisation of sports events, providing sports entertainment and allied activities.</p>
<p>The capitalized terms used but not defined herein shall have the same meaning assigned to them under the General T&amp;Cs.</p>
<p>INFORMATION WE MAY COLLECT AND HOW WE COLLECT IT</p>
<p>We may collect, store, disclose, and process the following data about you:</p>
<p>1. Information You Provide to Us:</p>
<p>Information which you may provide to us when you leave us a message, during customer support conversations survey, or for assistance in case of matters related to law enforcement or investigations such as name and contact details.</p>
<p>2. Information We Collect Automatically:</p>
<p>We may also collect certain Personal Data from you automatically through our servers that keep an activity log tracing all Users of the VEPL Platform. The information our servers collect may include but is not limited to your IP address, your registered domain or home server, time of access, date of access, web page(s) visited, number of clicks, software crash reports, type of browser used, session identification number, search terms, search results, and referring website’s addresses.</p>
<p>3. Information We Receive from Third Parties:</p>
<p>We may receive information about you from third parties if you have provided your consent for such information to be shared with us. We may combine and process such data internally towards our Services. We also work closely with select third parties (including, for example, subcontractors such as advertising networks, analytics providers, search information providers, infrastructural services, including cloud service providers) and may receive information about you from such sources. We may also collect and receive information in an aggregated form from different browser types for analysis.</p>
<p>USE OF COOKIES AND SIMILAR TECHNOLOGY</p>
<p>We, and/or our third parties may use cookies, which are small text files that are stored on your computer or equipment when you visit certain online pages that record your preferences ("Cookies"). We use Cookies to track your use of the VEPL Platform and their features. Some of the Cookies that we may use are as follows:</p>
<p>1. Strictly Necessary Cookies: These are Cookies that are required for the operation of the VEPL Platform. They include, for example, Cookies that enable you to log into secure areas of the VEPL Platform.</p>
<p>2. Analytical/Performance Cookies: These Cookies allow us to recognize and count the number of visitors and to see how visitors move around the VEPL Platform when they are using it. This helps us to improve the way the VEPL Platform works.</p>
<p>3. Functionality Cookies: These are used to recognize you when you return to the VEPL Platform. It enables us to remember your preferences.</p>
<p>4. Commercial Cookies: These Cookies are used to display personalized advertisements on the VEPL Platform as well as other third party websites.</p>
<p>You may occasionally get Cookies from companies with links on the VEPL Platform. We do not control these Cookies, and these Cookies are not subject to our Policy. You have the ability to accept or decline Cookies. Most web browsers automatically accept Cookies, but, if you prefer, you can usually modify your browser setting to decline Cookies. However, if you block all Cookies (including essential Cookies) you may not be able to access all or parts of the VEPL Platform. You agree that if you send us personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities or postings on VEPL the Platform, we may collect and/or store such information.</p>
<p>We use Google Analytics on the VEPL Platform. Google Analytics is a web analytics service provided by Google Inc. ("Google"). Google Analytics uses Cookies to collect anonymous traffic data to help us analyze how you use the VEPL Platform. The information generated by Cookies (including your IP address) will be transmitted to and stored by Google on servers. Google will use this information for the purpose of evaluating your use of the VEPL Platform, compiling user activity reports, and providing other services relating to the VEPL Platform's activity and internet usage. Google may also transfer this information to third parties where required to do so by Applicable Law, or where such third parties process the information on Google’s behalf. By using the VEPL Platform, you consent to the processing of data about you by Google in the manner and for the purposes described in this Policy.</p>
<p>PERSONAL DATA OF CHILDREN AND PERSONS WITH DISABILITIES</p>
<p>Please note that no Personal Data is collected from any person under the age of 18 (eighteen) years or any person with a disability. We will only collect Personal Data about children or persons with disabilities when we have the express consent from their parents or guardians and are able to verify the same to our satisfaction. In the event you fall within either of the above categories, you must request your parent/lawful guardian to write to us at info@devilscircuit.com, before you utilise any of our Services or access the VEPL Platform.</p>
<p>THIRD PARTY LINKS</p>
<p>Please be advised that the practices described in this Policy apply only to the information we gather on the VEPL Platform. The VEPL Platform may contain links to third party applications/websites such as links to third parties that we use for our event registration process. Please note that when you click on one of these links, you are entering another application/website over which we have no control and will bear no responsibility. We do not own or control these third party applications and when you interact with them you may be providing information directly to them or us or both. Such information provided by you is only processed for the purposes of the legitimate interests pursued by the third party. Often these third party applications/websites require you to enter your Personal Data and further, use and collect your Personal Data. The third party, in accordance with its privacy policy and the terms of use, utilizes this information. Since we do not control the privacy practices of these third parties, we encourage you to read the privacy statements/policies on all such applications/websites before deciding to use their services as their policies may differ from our Policy. You agree that we shall not be liable for any breach of the privacy of your Personal Data, or any loss incurred by your use of these third party applications/websites. This Policy DOES NOT apply to information that you provide to use, or that is collected by, any third party, such as social networks that you use which are not in connection with our Services. We encourage you to consult directly with such third parties about their privacy practices.</p>
<p>HOW WE MAY USE YOUR INFORMATION</p>
<p>By providing consent under this Policy, you grant us the permission to use, copy, transmit, process, disclose, store and back-up your Personal Data for the purposes mentioned below. We shall not be responsible for the authenticity of any information supplied by you to us or any other person acting on our behalf. Except as otherwise provided in this Policy, we do not use or disclose information about your individual visits to the VEPL Platform or your Personal Data collected online to any parties other than those with whom we partner or are affiliated with, without your consent. We may use your information for the following purposes:</p>
<p>1. Personal Data obtained in connection with the VEPL Platform:</p>
<p>We use your Personal Data in different ways, depending on the communications, or other interactions between you and the VEPL Platform. Below is a table describing how we use the Personal Data, why we do it, and the legal basis for each category of use.</p>
<table class="data-table">
        <thead>
            <tr>
                <th>Categories of Personal Data</th>
                <th>Purpose for Processing</th>
                <th>Legal Basis for Processing Personal Data</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td rowspan="3">Name and contact details</td>
                <td>Send service messages email and postal mail regarding the Services</td>
                <td>Consent</td>
            </tr>
            <tr>
                <td>Send information by email, SMS messaging and postal mail about new events and products</td>
                <td>Legitimate interests or consent</td>
            </tr>
            <tr>
                <td>Provide customer services and support</td>
                <td>Performance of a contract or legitimate interests</td>
            </tr>
            <tr>
                <td>Information we collect automatically</td>
                <td>Enable us to perform website analytics and to make our website and the advertising displayed on it, and the marketing messages we send to you more relevant to your interest</td>
                <td>Consent</td>
            </tr>
        </tbody>
    </table>
<p>2. Use of Anonymous Information:</p>
<p>We may use information that we collect automatically to help us determine how viewers use the VEPL Platform and who our users are in order to improve the VEPL Platform. We may also use this information to provide information in an aggregated and anonymised form to our partners and other third parties about how our users collectively use The VEPL Platform. We may also use or share this information (other than Personal Data) in any other manner that we deem appropriate or necessary, subject to your consent.</p>
<p>HOW WE SHARE YOUR PERSONAL DATA</p>
<p>Your Personal Data will be used by VEPL's affiliates for the purposes described above and for accounting, tax, and other administrative purposes. We shall keep the Personal Data confidential and take steps to prevent unauthorized disclosures of the same to the best of our ability. However, you agree we may disclose such information to:</p>
<p>1. Our personnel, representatives, agents, advisors, affiliates, partners and service providers including but not limited to services of processing, analytics, IT services, data storage, survey and market research;</p>
<p>2. Third parties in accordance with the contractual arrangements we have in place with them. Such third parties shall be bound by the same standards of data protection as we are under this Policy. We may share your Personal Data with third parties so that they can send you communications about their products and services, however we will only share your Personal Data under these circumstances if we receive your prior express consent. If we determine it to be necessary, we may also disclose your Personal Data to a third party:</p>
<p>(a) to comply with any Applicable Law, a court order, a request from law enforcement or other legal process;</p>
<p>(b) to protect the legitimate rights, privacy, property, interest or safety of the VEPL Platform, our personnel, customers, business partners or the general public;</p>
<p>(c) in relation to a change in ownership or control of VEPL Platform such as a merger, sale, acquisition, or bankruptcy. In such an event, we will notify you and take your prior consent before your Personal Data is transferred and becomes subject to a different privacy policy; or</p>
<p>(d) to prevent and detect actual or potential fraud or other illegal activities.</p>
<p>3. Persons to whom we are required to make disclosure under Applicable Laws and regulations.</p>
<p>DATA SECURITY</p>
<p>1. The VEPL Platform will maintain appropriate security measures to minimize the risk that Personal Data will be lost, used without authorization, or accessed without authorization. We do this by having in place a range of appropriate technical and organizational measures, including firewalls, encryption measures, and disaster recovery plans. All information you provide to us is secured on our servers. In public areas, you should exercise caution and not leave your computer/device unattended. The use of established malware and virus protection software and applications for your device is recommended.</p>
<p>2. Please remember that if you post any of your Personal Data in the public areas of the VEPL Platform, such information may be collected and used by others over whom we have no control.</p>
<p>3. We limit access to your Personal Data to those who have a legitimate business need to use it. Those who process your information will do so only in an authorized manner and are subject to a duty of confidentiality commensurate with our confidentiality obligations to you. We have implemented procedures to deal with suspected security incidents involving Personal Data and will notify the applicable regulators and you of such incidents as required by Applicable Law.</p>
<p>4. You understand and acknowledge that any transmission through the internet is not completely secure and is at your own risk. However, we will do our best to protect your Personal Data. We cannot guarantee or give any warranties in this regard, and hereby disclaim all liabilities for any breach of security, malicious attacks, error, omission or commission concerning the data transmitted through the VEPL Platform or leading to a breach of data or information of the User.</p>
<p>5. If you suspect any misuse or loss of or unauthorized access to your Personal Data, please let us know immediately. Please raise your concern with info@devilscircuit.com, in the first instance, and we will investigate the matter and update you as soon as possible on the next steps.</p>
<p>6. We may also subject ourselves to regular checks by third party security evaluation specialists and restrict access to your Personal Data by our personnel on a need-to-know basis only. Once we have received your Personal Data, we will use strict procedures and security features to try and prevent, as far as is reasonably possible, unauthorized access to your Personal Data.</p>
<p>RETENTION, STORAGE AND TRANSFER OF PERSONAL DATA</p>
<p>1. We will keep your Personal Data for the time necessary to fulfil the purposes for which it was collected, including any legal, accounting or reporting requirements. The retention period may be extended in accordance with the Applicable Law and the Personal Data shall only be used for such a period that is absolutely necessary for us to comply with the Applicable Law.</p>
<p>2. To determine the appropriate retention period, we consider the categories, amount, nature and sensitivity of Personal Data, the potential risk of harm from unauthorized use of disclosure of the Personal Data, the purposes for which we process the Personal Data, if we can achieve the purposes through other means, and the applicable legal requirements.</p>
<p>3. While we will endeavour to permanently erase your Personal Data once it reaches the end of its retention period, some of your Personal Data may still exist within our systems, for example if it is waiting to be overwritten. For our purposes, this data has been put beyond use, meaning that, while it still exists in the electronic ether, our personnel will not have any access to it or use it again. This Personal Data is subject to retention for purposes of complying with Applicable Laws, resolving disputes, enforcing the terms of our Policy, and protecting our rights.</p>
<p>4. Your Personal Data may be transferred to and stored in locations outside of India by us or our affiliates. We will comply with the applicable Data Protection Laws for such transfer.</p>
<p>YOUR RIGHTS</p>
<p>1. You may deal with Personal Data available with us in the following ways:</p>
<p>(a) To check whether we hold Personal Data about you and to access such data, and to ascertain the third parties with whom such Personal Data has been shared;</p>
<p>(b) To ask for a copy of the Personal Data we hold about you in a structured, commonly used, and machine readable format (or, when this is possible, ask for such Personal Data to be communicated on your behalf to another data controller);</p>
<p>(c) To require us to correct/update/complete the Personal Data relating to you that is inaccurate or deficient when communicated to us by you in writing, as soon as reasonably practicable;</p>
<p>(d) To ascertain our policies and practices in relation to Personal Data and the kind of Personal Data held by us;</p>
<p>(e) To object to the use of your Personal Data where we do so for one of the following reasons: (i) because it is in our legitimate interests to do so; (ii) to enable us to perform a task in the public interest or exercise official authority; (iii) to send you direct marketing materials; or (iv) for scientific, historical, research, or statistical purposes; and</p>
<p>(f) To revoke your consent for us to use your Personal Data and/or ask us to erase and forget your Personal Data.</p>
<p>2. To exercise your rights above, please send us requests on info@devilscircuit.com. We will process such requests within 1 (one) month of receipt of the request, which time period may be extended by 2 (two) further months where necessary. You may nominate any other individual to exercise your rights under this Policy in the event of your death or incapacity.</p>
<p>NON-PERSONAL INFORMATION COLLECTED BY US</p>
<p>1. Non-personal data or information is information that does not personally identify you. When you visit and interact with the VEPL Platform or third parties with whom we have contracted to provide services, non-personal information, like a list of website pages visited by you, could be collected.</p>
<p>2. We may collect data by using web beacons, clear GIFS, pixel tags or similar means, which will inform us when you visit the VEPL Platform. Non-personal information like the domain name, the areas of the VEPL Platform you visit, your operating system, your browser version, and your URL, can be identified, which can be used to enhance your online experience by understanding your web usage patterns.</p>
<p>3. We may use or disclose non-personal information for any purpose from time to time, for instance, we may embed e-mail addresses with images. In such cases where we combine non-personal information with Personal Data, the combined information will be treated by us as Personal Data as per this Policy.</p>
<p>DATA PROTECTION</p>
<p>1. We will: (i) comply with all applicable Data Protection Laws; (ii) comply with all standards that relate to Data Protection Law and the privacy and security of your Personal Data; (iii) refrain from any action or inaction that could cause breach of any Data Protection Laws; (iv) do and execute, or arrange to be done and execute, each act, document and thing we deem necessary in our business judgment to keep us compliant with the relevant Data Protection Laws; and immediately report theft or loss of Personal Data.</p>
<p>2. Any Personal Data collected or accessed by us shall be limited to that which is strictly necessary to perform our obligations in relation to the Services offered on by us or to fulfil any legal requirements. We shall use such Personal Data only as necessary in this regard and not for any other purpose. We shall maintain such Personal Data in strict confidence in accordance with the provisions of this clause. We shall not share any Personal Data that is collected or possessed by us with any third party for any reason except as expressly stated in this Policy.</p>
<p>3. You agree that other than as stated in this Policy, we shall have the right to collect and/or use or analyse the Personal Data on an anonymised basis and in no way shall the Personal Data be used in a way that can lead to or reveal your identity.</p>
<p>4. We advise you not to include Sensitive Personal Data in any emails you may send to us. Please do not send credit/debit card numbers or any other Sensitive Personal Data to us via email.</p>
<p>LIMITATION OF LIABILITY</p>
<p>For any breach of the provisions of this Policy, our liability shall be limited to any fees paid for our Services.</p>
<p>MODIFICATION OF THIS POLICY</p>
<p>We reserve the right to update, replace or amend this Policy from time to time, to comply with Applicable Law or to meet our changing business requirements. We will publish the revised Policy on the VEPL Platform. Any change will be effective immediately. You are encouraged to review this Policy from time to time to note any changes we make, as they will be binding on you. Continued use of the VEPL Platform constitutes agreement of the User to the terms contained herein and any amendments thereto.</p>
<p>SEVERABILITY</p>
<p>Whenever possible, each section of this Policy shall be interpreted in a manner so as to be valid under Applicable Law. However, if any terms and conditions of this Policy are found to be illegal, invalid or unenforceable by any court of competent jurisdiction, such terms and conditions of the Policy shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such Policy and the rest of this Policy shall remain in full force and effect.</p>
<p>GOVERNING LAW AND JURISDICTION</p>
<p>This Policy shall be governed by Indian laws as applicable and any disputes regarding or arising out of this Policy shall be subject to the exclusive jurisdiction of the courts at New Delhi.</p>
<p>NOTICES</p>
<p>All notices and communications shall be in writing, in English and shall be deemed given if delivered personally or by commercial messenger or courier service, or mailed by registered or certified mail (return receipt requested) or sent by email, with due acknowledgment or complete transmission to the following address:</p>
<p>Postal Address: Volano Entertainment Pvt Ltd- 809 Udyog Vihar, Phase V, Gurugram (Haryana) – 122016, India</p>
<p>Email id: <a href="mailto:info@devilscircuit.com">info@devilscircuit.com</a></p>
<p>Notices provided by personal delivery will be effective immediately. Notices provided by overnight courier will be effective 1 (one) business day after they are sent. Notices provided by registered mail will be effective 3 (three) business days after they are sent.</p>
<p>You may opt-out of receiving future email, SMS and postal mail marketing and other messages and newsletters from us by contacting us on the email above.</p>
<p>GRIEVANCE OFFICER AND DATA PROTECTION OFFICER</p>
<p>Please address your grievances, feedback, or questions, without limitation, with respect to the collection, processing, usage, disclosure, security of your information or any element of this Policy; or your intention to exercise one of your data protection rights in writing to:</p>
<p>1. Our Grievance Officer. If you are located in India:</p>
<p>Name: Neelesh Kumar</p>
<p>Email Address: <a href="mailto:Neelesh@devilscircuit.com">Neelesh@devilscircuit.com</a></p>
<p>2. Our Data Protection Officer (DPO), if you are located outside of India:</p>
<p>Name: Neelesh Kumar</p>
<p>Email Address: <a href="mailto:Neelesh@devilscircuit.com">Neelesh@devilscircuit.com</a></p>
<p><em>Last Updated: May 24, 2024</em></p>

                </div>


    </div>
}

export default PrivactPolicy;
