import React, { useState, useEffect, useRef } from "react";
import "./pageHead.css";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import photoImage from "../../../assets/Photographs.webp"

const PageHead = (props) => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  var aspectRatio = 1443 / 600;
  var cardHeight = screenWidth / aspectRatio;

  const actualSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", actualSize);
    return () => {
      window.removeEventListener("resize", actualSize);
    };
  }, []);
  const {
    page_name,
    page_head,
    text_1,
    text_2,
    text_3,
    spanText,
    media,
    media_type,
  } = props;

  return (
    <div className="page-head">
      <div className="page-text">
        <p className="page-name">{page_name}</p>
        <p className="page-heading">{page_head}</p>
        {text_1 !== null && (
          <p className="page-content">
            {text_1} {spanText && <span>{spanText}</span>}
            {text_2 !== null && (
              <>
                <br /> {text_2}{" "}
              </>
            )}
            {text_3 !== null && (
              <>
                <br /> {text_3}
              </>
            )}
          </p>
        )}
      </div>

      {media_type === "image" && (
        <div className="page-banner">
          <img src={media} alt="image" />
        </div>
      )}

      {media_type === "video" && (
        <div className="page-video">
          <video
            style={{ height: cardHeight }}
            ref={videoRef}
            className="page-video-tag"
            controls={false}
            preload="true"
            loop={true}
            autoPlay={true}
            playsInline
            muted
          >
            <source src={media} type="video/mp4" />
          </video>

          <button className="video-btn" onClick={toggleMute}>
            {isMuted ? (
              <VolumeOffIcon
                style={{ fontSize: "18px", color: "white", cursor: "pointer" }}
              />
            ) : (
              <VolumeUpIcon
                style={{ fontSize: "18px", color: "white", cursor: "pointer" }}
              />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default PageHead;
