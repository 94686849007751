import React, { useState, useEffect } from "react";
import "./photos.css";
import FindRace from "../common/findRaceFooter/findrace";
import { Grid } from "@mui/material";
import { photos } from "../../assets/photos/photostext";
import PageHead from "../common/pageHead/pageHead";
import Select from "react-select";
import { Link } from "react-router-dom";

const options = [{ value: "24", label: "2024-25" }];

const Photos = () => {
  const [selectedValue, setSelectedValue] = useState("2024-25");
  const [selectedOption, setSelectedOption] = useState("24");
  const [selected, setSelected] = useState([{ value: "24", label: "2024-25" }]);

  const handleChange = (selected) => {
    setSelected(selected);
    setSelectedValue(selected.label);
    setSelectedOption(selected.value);
  };

  const [selectedArr, setSelectedArr] = useState(photos.twothree);

  useEffect(() => {
    if (selectedOption === "24") {
      setSelectedArr(photos.twothree);
    }
  }, [selectedOption]);

  return (
    <div className="photos_wrapper">
      <PageHead
        page_name="Photographs"
        page_head={photos.photoshead}
        text_1={photos.photosubtext}
        text_2={null}
        text_3={null}
        media={
          "https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcNewImages/Photograph_11zon.webp"
        }
        media_type="image"
      />
      <div className="photos_cards_div">
        <div className="photos_dropdown">
          <p>Select Year</p>
          <Select
            isSearchable={false}
            value={selected}
            onChange={handleChange}
            options={options}
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: "0px",
                border: "1px solid #aeaeae",
                boxShadow: "none",
                "&:focus": {
                  border: "1px solid #aeaeae",
                  boxShadow: "none",
                },
              }),
            }}
          />
        </div>
        <Grid container columnGap={3} className="photos-events-list">
          {selectedArr.map((item) => (
            <Grid item key={item.id} lg={3.8} md={5.5} sm={12} xs={12}>
              <Link to={item.link} target="_blank" className="photos-link">
                <div>
                  <img
                    src={item.img}
                    alt={item.city}
                    className="city-image-photo"
                  />
                  {item.city}
                </div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>

      <FindRace
        heading="Unleashing the Power of Resistance!"
        para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance."
        btn_text="Find Your Race"
      />
    </div>
  );
};

export default Photos;
