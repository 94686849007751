// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/village/divider.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headingTerms{
    color: #BA1E24;
font-family: Thicker;
font-size: 54px;
font-style: normal;
font-weight: 500;
line-height: 126.6%; /* 68.364px */
text-transform: uppercase;
margin-left: 80px;
margin-bottom: 40px;
margin-top: 80px;
}
.termsImage img{
    width: 100%;
}
.innerheading{
    margin-top: 20px;
    text-align: center;
}
.textOuterDiv{
    padding: 0px 80px;
}

.textOuterDiv > p > a {
  color: #BA1E24 !important;
}

.terms-divider {
    height: 80px;
    width: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: repeat;
    /* background-size: cover; */
  }

  @media screen and (max-width: 1100px) {
    .terms-divider {
      height: 6vw !important;
      background-size: cover;
    }
  }
  
  @media screen and (max-width: 600px) {
    .terms-divider {
      height: 40px !important;
    }
    .textOuterDiv {
        padding: 20px 20px;
    }
    .headingTerms{
        margin-left: 16px;
        font-size: 32px;
        margin-bottom: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/termsandcondition/termsandcondition.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB,oBAAoB;AACpB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,mBAAmB,EAAE,aAAa;AAClC,yBAAyB;AACzB,iBAAiB;AACjB,mBAAmB;AACnB,gBAAgB;AAChB;AACA;IACI,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,yDAAuD;IACvD,yBAAyB;IACzB,4BAA4B;EAC9B;;EAEA;IACE;MACE,sBAAsB;MACtB,sBAAsB;IACxB;EACF;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;QACI,kBAAkB;IACtB;IACA;QACI,iBAAiB;QACjB,eAAe;QACf,mBAAmB;IACvB;AACJ","sourcesContent":[".headingTerms{\n    color: #BA1E24;\nfont-family: Thicker;\nfont-size: 54px;\nfont-style: normal;\nfont-weight: 500;\nline-height: 126.6%; /* 68.364px */\ntext-transform: uppercase;\nmargin-left: 80px;\nmargin-bottom: 40px;\nmargin-top: 80px;\n}\n.termsImage img{\n    width: 100%;\n}\n.innerheading{\n    margin-top: 20px;\n    text-align: center;\n}\n.textOuterDiv{\n    padding: 0px 80px;\n}\n\n.textOuterDiv > p > a {\n  color: #BA1E24 !important;\n}\n\n.terms-divider {\n    height: 80px;\n    width: 100%;\n    background-image: url(../../assets/village/divider.svg);\n    background-repeat: repeat;\n    /* background-size: cover; */\n  }\n\n  @media screen and (max-width: 1100px) {\n    .terms-divider {\n      height: 6vw !important;\n      background-size: cover;\n    }\n  }\n  \n  @media screen and (max-width: 600px) {\n    .terms-divider {\n      height: 40px !important;\n    }\n    .textOuterDiv {\n        padding: 20px 20px;\n    }\n    .headingTerms{\n        margin-left: 16px;\n        font-size: 32px;\n        margin-bottom: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
