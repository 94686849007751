import React from "react";
import "../community/community.css";
import PageHead from "../common/pageHead/pageHead";
import FindRace from "../common/findRaceFooter/findrace";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useEffect } from "react";
import CommunitySlider from "./CommunitySlider";

const Community = () => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);

  var aspectRatio = 1286 / 502;
  var cardHeight = screenWidth / aspectRatio;

  const actualSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", actualSize);
    return () => {
      window.removeEventListener("resize", actualSize);
    };
  }, []);

  return (
    <>
      <PageHead
        page_name={"Community"}
        text_1={
          "Maruti Suzuki Arena Devils Circuit is the rockstar of running events. it is Asia's biggest Obstacle Course Race and the only running event in India to boast a community of over two million runners! But we believe our runners can do much more than conquer a rugged stretch of land, therefore, we have created a five-kilometre track with 15 crazy, fun obstacles to conquer before you reach the Finish Line. Isn't that cool?"
        }
      />

      <CommunitySlider cardHeight={cardHeight} />

      {/* <MovingBanner showBorder={false} />

      <Testimonial /> */}

      <FindRace
        heading="Unleashing the Power of Resistance!"
        para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance."
        btn_text="Find Your Race"
      />
    </>
  );
};

export default Community;
