import "./termsandcondition.css";

const TermsAndCondition = () => {
    return <div>
        <div className="headingTerms">
        TERMS AND CONDITIONS
                </div>
                {/* <div className="termsImage">
                <img src="https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcunder500/T%26C+(1).jpg"/>
                </div>
                <div className="terms-divider"></div> */}

            <div className="textOuterDiv">
            {/* <p className="innerheading">GENERAL TERMS AND CONDITIONS</p> */}
<p>This document is an electronic record and is governed by the provisions under the Information Technology Act,2000 and rules made thereunder as may be applicable, and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
<p>Volano Entertainment Private Limited is a company registered in India under the Companies Act 1956 having its registered office at House No. 148 T/F Block No. 1 Subhash Nagar, West Delhi, New Delhi – 110027, India, and its corporate office at 809 Udyog Vihar, Phase V, Gurgaon 122016 (referred to as "VEPL", "we" or "us") in these General Terms and Conditions ("General T&Cs"). The terms "you", "your" or "User", refer to all individuals and/or entities browsing, accessing or using the mobile application and/or the VEPL website www.devilscircuit.com (collectively referred to as the "VEPL Platform"), for understanding our Services (as defined hereinafter) and obtaining information regarding the same or any other purpose.</p>
<p>These General T&Cs form a binding agreement between you and VEPL and its affiliates and subsidiaries. Please take a moment to familiarize yourself with our General T&Cs, Privacy Policy and any other terms and conditions published on the VEPL Platform (collectively referred to as "VEPL Policies") before you start to use the VEPL Platform. If you do not agree with any provisions of the VEPL Policies, we advise you to not use or access the VEPL Platform. The VEPL Policies apply to your use of the VEPL Platform. The VEPL Policies apply regardless of how you access the VEPL Platform, including any technologies or devices by which VEPL makes the VEPL Platform available to you, at home, or on the move. By accessing, browsing, using, registering with, or placing an order on the VEPL Platform, you confirm that you have read, understood and you expressly agree to be bound by the VEPL Policies in their entirety. If you do not agree to the VEPL Policies, please do not download, install or use the VEPL Platform and delete the VEPL Platform from your device.</p>
<p>Please note that we may from time to time change the VEPL Policies that govern your use of the VEPL Platform. Every time you wish to use our VEPL Platform, please check these VEPL Policies to ensure you understand the terms and conditions that apply at that time. Further, please note that we reserve the right to either change the format and the content of the VEPL Platform or suspend the operation of the VEPL Platform for support or maintenance work, in order to update the content or for any other reason, at any time, at our sole discretion.</p>
<p>Only persons who can enter into legally binding contracts as per the Indian Contract Act, 1872, i.e., persons who are 18 (Eighteen) years of age or older, are of sound mind, and are not disqualified from entering into contracts by any Applicable Law (as defined hereinafter), can use the VEPL Platform. If you are a minor i.e., under the age of 18 (Eighteen) years, you may use the VEPL Platform only with the supervision and consent of a parent or guardian.</p>
<p>By accepting these General T&Cs, you affirm that you are 18 (Eighteen) years of age or above and are fully competent to enter into these General T&Cs, and to abide by and comply with these General T&Cs. In the event that we are made aware that a User is under the age of 18 years and is using/browsing the VEPL Platform without the supervision and consent of his/her parent or legal guardian, we reserve the right to deny access to the VEPL Platform to such User.</p>
<p><strong>DEFINITIONS</strong><br/><strong>Applicable Laws</strong> shall mean any and all: (i) laws, statutes, constitutions, treaties, rules, regulations, ordinances, codes, guidance, and common law; and (ii) all judicial, executive, legislative, administrative or military orders, directives, decrees, injunctions, judgments, permits, agreements, and other legal requirements, in each case, of, with, or adopted or imposed by any governmental authority, now or hereafter in effect and, in each case, as amended from time to time.</p>
<p><strong>Data Protection Law</strong> shall mean any data protection, data security or privacy law, including, without limitation, the Information Technology Act, 2000 including the rules thereunder ("IT Act and Rules"), the Digital Personal Data Protection Act, 2023, and any other applicable laws governing Personal Data, communication-related data protection laws, data security or privacy laws.<br/><strong>Personal Data</strong> shall mean any personally identifiable information relating to an identified or identifiable individual, including data that identifies an individual or that could be used to identify, locate, track, or contact an individual. Personal Data includes both directly identifiable information, such as name, identification number or unique job title, and indirectly information such as date of birth, unique mobile or wearable device identifier, information that could be used to identify a household, telephone number, key-coded data or online identifier, such as Internet protocol (IP) addresses, and includes any data that constitutes "personal data" or "sensitive personal data or information" under the IT Act and Rules or similar terms under other Data Protection Law. However, Personal Data excludes information that is made public by an individual (such as their social media profiles, or posts on public parts of public platforms online) and/or any other information made public as required under law.</p>
<p><strong>Products</strong> shall mean tickets to the Maruti Suzuki Arena Devils Circuit events or any other current or future events as well as merchandise related to the same including its sub-brands offered for sale to you on the Ticketing Platforms (as defined hereinafter).</p>
<p><strong>Sensitive Personal Data or Information</strong> with respect to a person shall mean such personal information which consists of information relating to:</p>
<p>• Biometric information;<br/>• Financial information such as bank account or credit card or debit card or other payment instrument details;<br/>• Medical records and history;<br/>• Password;<br/>• Physical, physiological and mental health conditions; and<br/>• Sexual orientation;<br/>• Any detail relating to the above clauses as provided to us for providing the Services; and<br/>• Any information received under above clauses by us for processing, stored or processed under lawful contract or otherwise.</p>
<p><strong>Services</strong> shall mean and include (without limitation) organisation of sports events, providing sports entertainment and allied activities.</p>
<p><strong>Intellectual Property Rights</strong> shall mean and include: (i) all rights, title, and interest under any statute or under Applicable Law including patent rights; copyrights including moral rights; and any similar rights in respect of the Intellectual Property, anywhere in the world, whether negotiable or not; (ii) any licenses, permissions and grants in connection therewith; (iii) applications for any of the foregoing and the right to apply for them in any part of the world; (iv) right to obtain and hold appropriate registrations in Intellectual Property anywhere in the world; (v) all extensions and renewals thereof; and (vi) causes of action in the past, present or future, related thereto including the rights to damages and profits, due or accrued, arising out of past, present or future infringements or violations thereof and the right to sue for and recover the same.</p>
<p><strong>OUR SERVICES</strong></p>
<p>Please note that this VEPL Platform only enables the Users to access information regarding our Services, the VEPL Policies, communicate with us, or raise support requests. The Users can avail our Services on third party platforms such as getmybib.com and townscript.com ("Ticketing Platforms"). Our Products are listed on such Ticketing Platforms. You may be required to provide your consent to our terms and conditions and privacy policy, which is specific to the provision of our Products on such Ticketing Platforms before placing an order on such Ticketing Platforms. Please note that your use of such Ticketing Platforms will also be governed by the policies of such Ticketing Platforms. We encourage you to read such policies prior to purchasing any Products on such Ticketing Platforms.</p>
<p><strong>USE OF THE VEPL PLATFORM</strong></p>
<p>VEPL and its group companies, subsidiaries, affiliates, officers, employees, agents, partners and licensors make no warranty that any errors on the VEPL Platform will be corrected within a specific time period. Any material downloaded or otherwise obtained through the use of the VEPL Platform is accessed at your own discretion and risk, and you will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material. No advice or information, whether oral or written, obtained by you from VEPL or through the use of the VEPL Platform shall create any warranty not expressly stated in these General T&Cs.</p>
<p>You will use the VEPL Platform in accordance with Applicable Laws and will not use it in a way that infringes the rights of anyone else (including without limitation their Intellectual Property, privacy, publicity or contract rights) or that restricts or inhibits anyone else's enjoyment of the VEPL Platform.</p>
<p>You will not undertake any activity that is harmful to the VEPL Platform or its content or otherwise not envisaged through the VEPL Platform. You have a limited license to access and use the VEPL Platform, solely for the purpose of accessing information regarding our Services, to communicate with us, or raise support requests, subject to these General T&Cs. While using the VEPL Platform, Users must adhere to the following:</p>
<p>1. You may not, without our prior written consent, copy, reproduce, crawl, frame, republish, download, print, post, distribute, re-post, broadcast, record, transmit, edit, communicate to the public, link to, deep-link into, or distribute in any way the web pages or materials on the VEPL Platform or the computer codes or elements comprising the VEPL Platform.</p>
<p>2. You may not use the content of the VEPL Platform for any commercial purposes whatsoever. We authorise you to view and access the VEPL Platform solely for accessing information regarding our Services, communicating with us or raising support requests, in accordance with these General T&Cs. We therefore, grant you a limited, revocable, non-exclusive and non-transferable permission to access and use the VEPL Platform for the aforesaid purpose. The license granted to you does not include a license for:</p>
<p>(a) Commercial use of the VEPL Platform or any content therein;</p>
<p>(b) Any collection and use of the description of our Services;</p>
<p>(c) Any use of VEPL Platform or any content therein other than as contemplated in these General T&Cs;</p>
<p>(d) Any use of data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) any parts of VEPL Platform, and</p>
<p>(e) Creating and/ or publishing your own database that features parts of the VEPL Platform.</p>
<p>3. In your use of VEPL Platform, you may enter into correspondence with, purchase goods and/or services from, or participate in promotions of advertisers or members or sponsors of VEPL Platform or access any other third party website linked to the VEPL Platform. Unless otherwise stated, any such correspondence, advertisement, purchase or promotion, including the delivery of and the payment for goods and/or services, and any other term, condition, warranty or representation associated with such correspondence, purchase or promotion, is solely between you and the applicable third party. You agree that we have no liability, obligation or responsibility for any such correspondence, purchase or promotion, access or usage of any third party website and the contract under such instances remains between you and any such third party.</p>
<p>4. You will not misuse the VEPL Platform for any purpose that is prohibited by these General T&Cs and/or the Privacy Policy.</p>
<p>VEPL will have the right to investigate and prosecute violations of any of the above and pursue any and all legal remedies available as per Applicable Law. VEPL may involve and cooperate with law enforcement authorities in prosecuting Users who violate any VEPL Policies. You acknowledge that VEPL has no obligation to monitor your access to or use of the VEPL Platform but has the right to do so for the purpose of operating the VEPL Platform and: (i) to ensure your compliance with VEPL Policies, including investigation of potential violations hereof; (ii) to comply with Applicable Law or the order or requirement of a court, administrative agency or other governmental body; (iii) to detect, prevent, or otherwise address fraud, security or technical issues; (iv) to respond to User support requests; or (v) to protect our rights, property or safety of our Users and the public.</p>
<p><strong>USE OF THE VEPL PLATFORM FROM OUTSIDE INDIA</strong></p>
<p>Except where otherwise specified on the VEPL Platform, the materials on the VEPL Platform are displayed solely for the purposes of promoting our Services available in India. Those who choose to access the VEPL Platform from locations outside India are entirely responsible for compliance with all the Applicable Laws of that country to the extent any such local laws are applicable. These General T&Cs do not constitute, nor may these General T&Cs be used for or in connection with any promotional activities or solicitation by anyone in any jurisdiction in which such promotional activities or solicitation are not authorized or to any person to whom it is unlawful to promote or solicit.</p>
<p><strong>OWNERSHIP OF SUBMISSIONS</strong></p>
<p>Other than Personal Data (which is subject to our Privacy Policy), you grant to us a royalty-free, perpetual, irrevocable, non-exclusive right and license to adopt, publish, modify, reproduce, disseminate, transmit, distribute, copy, use, create derivative works from, display worldwide, or act on any material, all comments, blogs, feedback, suggestions, questions, ideas, artwork, images, product or marketing ideas and any other submissions disclosed, submitted or offered to VEPL on or through the VEPL Platform or otherwise disclosed, submitted or offered by you (collectively, "Submissions"). These Submissions shall become and remain the property of VEPL once submitted. You must comply with the User content standards set out in these General T&Cs. You warrant that you own or have the right to use any Submissions and that your Submissions will comply with these standards and do not breach any applicable agreement and will not cause injury to any person or entity (including that the content or material is not defamatory). If at any time, the Submissions provided by you are found to be false or inaccurate, we will have the right to restrict you from using the Services in the future without any prior intimation whatsoever. You agree to indemnify us and our affiliates for all claims brought by a third party against us or our affiliates arising out of or in connection with a breach of any of such warranties made by you.</p>
<p><strong>SOCIAL NETWORKING SITES</strong></p>
<p>Any submissions you make on any third party website or pages such as Facebook page, Twitter, Pinterest, YouTube or other social networking site are governed by the terms of use and policies of the respective website/platform. The User should read the terms of use, privacy policy and/or other policies of the respective website/platform and any access or use of the website/platform is entirely at the User's own risk. All comments, images, videos and any other type of material posted on any website/platform of third parties do not necessarily reflect the opinions or ideas of VEPL or its employees and VEPL is not responsible for any such content.</p>
<p><strong>OWNERSHIP OF RIGHTS</strong></p>
<p>1. <strong>Data Usage and Retention</strong></p>
<p>The Personal Data that you provide to us or otherwise authorise to have access to is your property. By accessing the VEPL Platform, you grant us a license to collect, use, copy, transmit, store and back-up your Personal Data for the purposes contemplated by the Privacy Policy. All Personal Data provided to us by you shall be governed by the Privacy Policy.</p>
<p>2. <strong>Intellectual Property</strong></p>
<p>All rights, including copyright and other Intellectual Property Rights, in and to the VEPL Platform, Products or arising out of the Services are owned by or licensed to VEPL. We grant you a non-exclusive, limited, royalty-free and revocable license to use the VEPL Platform to access information regarding our Services and/or Products. Your use of the VEPL Platform and its contents grants no rights to you in relation to our Intellectual Property Rights, or that of third parties, in the VEPL Platform or its contents. By submitting information (other than your Personal Data), text, photos, graphics or other content to the VEPL Platform you confirm that you have the right to use the same and grant us a right to use such materials at our own discretion (with or without accreditation) in any media including, without limitation, to edit, copy, reproduce, disclose, post and remove such materials from the VEPL Platform.</p>
<p>A disclosure, comments and your agreement to the VEPL Policies shall constitute an assignment to VEPL of any Intellectual Property Rights. Whenever you make use of a feature that allows you to upload material to our VEPL Platform, including questions, or to make contact with other users of our VEPL Platform or to make Submissions on any VEPL related third party website/platform, you must comply with the content standards set out in the VEPL Policies. Any third-party advertising on VEPL pages of third-party social networking sites/third party platforms are not the responsibility of, or endorsed by VEPL. Any use of any VEPL social networking pages or their contents, including copying or storing them in whole or in part, is prohibited without the permission of VEPL. You confirm that the Submissions made by you on the VEPL Platform or the third party platform is your own and that the content does not infringe the material, trademarks or Intellectual Property Rights of others. You further agree to execute all such documents and do all such acts and things as we may reasonably require in order to assign any such rights to us.</p>
<p>All the trade names associated with us and logos denoted with TM or ® are trademarks or registered trademarks of us or our affiliates. Our trademarks may not be used in connection with any product or service that is not our Product or Service.</p>
<p><strong>CONFIDENTIALITY</strong></p>
<p>Any material you upload to our VEPL Platform will be considered non-confidential and non-proprietary, and we have the right to use, copy, distribute and disclose to third parties any material in the submissions for any purpose. We also have the right to disclose your identity to any third-party who is claiming that any material posted or uploaded by you to our VEPL Platform or any VEPL related third party social networking site constitutes a violation of their Intellectual Property Rights, or of their right to privacy if permitted by Applicable Law. We will not be responsible, or liable to any third party, for the content or accuracy of any materials posted by you or any other user of our VEPL Platform. We have the right to remove any Submissions you make on our VEPL Platform if, in our opinion, such Submission does not comply with the content standards set out in these General T&Cs.</p>
<p>During the course of your use of the VEPL Platform you may receive information relating to us or to our Services, that is not known to the general public ("Confidential Information"). You agree that: (i) all Confidential Information will remain VEPL's exclusive property; (ii) you will use Confidential Information only as is reasonably necessary for your participation in our Services and use of the VEPL Platform; (iii) you will not otherwise disclose Confidential Information to any other person or third party; and (iv) you will take all reasonable measures to protect the Confidential Information against any use or disclosure that is not expressly permitted in these General T&Cs and/or the Privacy Policy. You may not issue any press release or make any public statement related to our Services, or use our name, trademarks, or logo, in any way (including in promotional material) without our advance written permission or misrepresent or embellish the relationship between us in any way.</p>
<p><strong>USER WARRANTIES AND RESTRICTIONS</strong></p>
<p>You are prohibited from hosting, displaying, uploading, modifying, publishing, transmitting, updating or sharing on or through the VEPL Platform, any information that:<br/>• Deletes or modifies any content on the VEPL Platform, such as any information regarding the Services, their performance, sales or pricing;<br/>• Uses any engine, software, tool, agent or other mechanism (such as spiders, robots, avatars, worms, time bombs etc.) to navigate or search the VEPL Platform;<br/>• Makes false or malicious statements against the Products, Services, the VEPL Platform or us;<br/>• Posts, copies, submits, uploads, distributes, or otherwise transmits or make available any software or other computer files that contain a virus or other harmful component, or otherwise disrupt or damage the VEPL Platform or any connected network, or otherwise interfere with any person or entity’s use or enjoyment of the VEPL Platform and/or the Services;<br/>• Introduces any trojans, viruses, any other malicious software, any bots or scrape the VEPL Platform for any information;<br/>• Probes, scans, or tests the vulnerability of any system, security or authentication measures implemented by us or otherwise tamper or attempt to tamper with our technological design and architecture;<br/>• Hacks into or introduce malicious software of any kind onto the VEPL Platform;<br/>• Gains unauthorized access to, or interfere with, or damage, or disrupt the server on which the details connected to the services are stored, or any other server, computer, or database connected to the services;<br/>• Engages in any form of antisocial, disruptive, or destructive acts, including "flaming", "spamming", "flooding", "trolling", "phishing" and "griefing" as those terms are commonly understood and used on the internet;<br/>• Belongs to another person and to which you do not have any right;</p>
<p>• Is harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, invasive of another’s privacy, hateful, relating to or encouraging money laundering or gambling, or otherwise harmful in any manner whatsoever;<br/>• Harms minors in any way;<br/>• Infringes any patent, trademark, copyright or other Intellectual Property Rights;<br/>• Violates any Applicable Law for the time being in force;<br/>• Through any act or omission, threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevent investigation of any offence;<br/>• Post, upload, display, publish, submit or transmit any information or content that violates, or encourages any conduct that would violate, any Applicable Law or regulation or would give rise to civil liability;<br/>• Deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; and/or<br/>• Impersonates or defames another person.</p>
<p>VEPL reserves the right, at any time and without prior notice, to remove, suspend, edit, or disable access to any information or content that VEPL, at its sole discretion, considers to be objectionable for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such content), in violation of these General T&Cs or otherwise harmful to the VEPL Platform or Services.</p>
<p><strong>DISCLAIMER OF WARRANTIES AND ACCURACY OF CONTENT</strong></p>
<p>We will try to ensure that all information and recommendations, whether in relation to the Services, offerings or otherwise (hereinafter "Information") provided as part of this VEPL Platform is correct at the time of inclusion on the VEPL Platform. We do not guarantee the accuracy of the Information. We make no representations or warranties as to the completeness or accuracy of Information. To the extent permitted by Applicable Law, VEPL disclaims all representations and warranties, express or implied, that content or Information displayed in or on the VEPL Platform is accurate, complete, up-to-date and/or does not infringe the rights of any third party.</p>
<p>The views expressed in user generated content are the opinions of those users and do not represent the views, opinions, beliefs or values of VEPL or any of its group companies, affiliates, directors, employees or officers.</p>
<p>You agree that Information is being supplied to you on the condition that you will make your own determination as to the suitability of such Information for your purpose prior to use or in connection with the making of any decision. No Information on the VEPL Platform shall constitute an invitation to invest in us or any affiliates. Any use of the VEPL Platform or the Information is at your own risk. Neither we, our affiliates, nor their officers, employees or agents shall be liable for any loss, damage or expense arising out of any access to, use of, the VEPL Platform, the Information, or any third party website linked to the VEPL Platform. We are not responsible for the content of any third party sites and do not make any representations regarding the content or accuracy of material on such sites. If you decide to access links of any third party platforms, you are doing so entirely at your own risk and expense.</p>
<p>Nothing contained herein is to be construed as a recommendation to use any Product, process, equipment or formulation, in conflict with any patent, or otherwise and we make no representation or warranty, express or implied that, the use thereof will not infringe any patent, or otherwise.</p>
<p>THIS VEPL PLATFORM IS PROVIDED TO YOU ON AN "AS IS" AND "WHERE-IS" BASIS, WITHOUT ANY REPRESENTATIONS OR WARRANTIES. WE, FOR OURSELF AND ANY THIRD-PARTY PROVIDING MATERIALS, SERVICES, OR CONTENT TO VEPL PLATFORM, MAKE NO REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF THIRD-PARTY RIGHTS, WITH RESPECT TO THE VEPL PLATFORM, THE INFORMATION OR ANY PRODUCTS OR SERVICES TO WHICH THE INFORMATION REFERS. WE WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, ARISING FROM OR CONNECTED WITH THE VEPL PLATFORM, INCLUDING BUT NOT LIMITED TO, YOUR USE OF THE VEPL PLATFORM OR YOUR INABILITY TO USE THE VEPL PLATFORM, EVEN IF WE HAVE PREVIOUSLY BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
<p>To the extent permitted by Applicable Law, we, our officers, agents, employees and directors, disclaim any liability against any loss, damage, expenses, liabilities, claim, injury caused due to the failure of performance, omission, defect of products, or deletion, interruption, error, delay, virus, communication, unauthorised access, theft, destruction, alteration or use of records on the VEPL Platform.</p>
<p><strong>DAMAGE TO YOUR COMPUTER OR OTHER DEVICES</strong></p>
<p>VEPL uses reasonable efforts to ensure that the VEPL Platform is free from viruses and other malicious or harmful content. However, we cannot guarantee that your use of the VEPL Platform (including any content on it or any website accessible from it) will not cause damage to your computer or other device. It is your responsibility to ensure that you have the right equipment (including antivirus software) to use the VEPL Platform safely and to screen out anything that may damage or harm your computer/ mobile phone or other device or other system or equipment through which the user uses and accesses the VEPL Platform. Except where required by Applicable Law, VEPL shall not be liable to any person for any loss or damage they suffer as a result of viruses or other malicious or harmful content that they access from or via the VEPL Platform.</p>
<p><strong>COMMUNICATION AND UNSUBSCRIBING</strong></p>
<p>By providing your consent, you also accept to receive news, updates, offers/ campaign related SMS, to the email address and mobile phone number provided by you. By accessing and using the VEPL Platform and/or verifying your contact number with us, you explicitly consent to receive such communications (through call, SMS, email or other digital and electronic means) from us and/or our authorized representatives regarding any new services or offerings, even if your contact number is registered under the DND/NCPR list under the Telecom Commercial Communications Customer Preference Regulations, 2018. You can unsubscribe/ opt-out from receiving marketing/ promotional communications, newsletters and other notifications from us at any time by following the instructions set out in such communications (if any) or by contacting our customer care.</p>
<p><strong>LIMITATION OF LIABILITY</strong></p>
<p>You have certain rights as a consumer, including statutory rights relating to faulty or misrepresented goods. Nothing in these General T&Cs will affect these statutory rights. We are only responsible for losses that are a natural, foreseeable consequence of our breach of these General T&Cs. We will not be liable to you if we are prevented or delayed from complying with our obligations under these General T&Cs by anything you (or anyone acting on your behalf) do or fail to do or due to events which are beyond our reasonable control. We shall not, under any circumstance, whether as a result of breach of contract or warranty, tort, or otherwise, be liable for any incidental, special or consequential damages, including loss of profits or revenues resulting directly or indirectly, or any other costs or damages. For any breach of the provisions of these General T&Cs, our liability shall be limited to any fees paid for our Services.</p>
<p><strong>INDEMNIFICATION</strong></p>
<p>You agree to indemnify, defend and hold harmless us, our affiliates, officers, directors, employees, consultants, licensors, agents, and representatives from and against any and all third party claims, losses, liabilities, damages, and/or costs (including reasonable attorney fees and costs) that we may sustain, directly or indirectly, arising from your access to or use of VEPL Platform, in violation of these General T&Cs, or infringement of any of our or any third party Intellectual Property Rights or other rights. We will notify you promptly of any such claim, loss, liability, or demand, and in addition to the foregoing, you agree to provide us with reasonable assistance, at your expense, in defending any such claim, loss, liability, damage, or cost.</p>
<p><strong>OTHER LEGAL NOTICES</strong></p>
<p>There may be legal notices on other areas of the VEPL Platform which relate to your use of the VEPL Platform, all of which will, together with the VEPL Policies govern your use of the VEPL Platform.</p>
<p><strong>MODIFICATION OF THE GENERAL T&Cs</strong></p>
<p>We reserve the right to update, replace or amend these General T&Cs from time to time to comply with Applicable Law or to meet our changing business requirements without prior notification. We would recommend that you regularly read the notices posted on the VEPL Platform from time to time so that you stay informed as to any such changes. Unless specified otherwise, any such deletions or modifications shall be effective immediately upon VEPL posting the same thereof. Any updates or amendments will be posted on the VEPL Platform. Your relationship with the VEPL Platform will be governed by the most current version of these General T&Cs, as published on the VEPL Platform.</p>
<p><strong>ASSIGNMENT AND THIRD PARTY RIGHTS</strong></p>
<p>You may not assign or sub-contract any of your rights or obligations under these General T&Cs to any third party unless we agree in writing. We may assign, transfer or sub-contract any of our rights or obligations under these General T&Cs to any third party at our discretion. Only you and we shall be entitled to enforce these General T&Cs. No third party shall be entitled to enforce any of these General T&Cs.</p>
<p><strong>WAIVER</strong></p>
<p>No relaxation or delay by us in exercising any right or remedy under these General T&Cs shall operate as waiver of that right or remedy nor shall it affect our ability to subsequently exercise that right or remedy. Any waiver must be agreed by us in writing.</p>
<p><strong>SEVERABILITY</strong></p>
<p>Whenever possible, each section of these General T&Cs shall be interpreted in a manner so as to be valid under Applicable Law. However, if any terms and conditions of these General T&Cs are found to be illegal, invalid or unenforceable by any court of competent jurisdiction, such terms and conditions of the General T&Cs shall be ineffective only to the extent of such prohibition or invalidity, without invalidating the remainder of such General T&Cs and the rest of these General T&Cs shall remain in full force and effect.</p>
<p><strong>ENTIRE AGREEMENT</strong></p>
<p>The VEPL Policies set out the entire agreement between you and us and supersede any and all prior terms, conditions, warranties and/or representations to the fullest extent permitted by Applicable Law.</p>
<p><strong>GOVERNING LAW AND JURISDICTION</strong></p>
<p>These General T&Cs shall be governed by Indian laws as applicable and any disputes regarding or arising out of these General T&Cs shall be subject to the exclusive jurisdiction of the courts at New Delhi.</p>
<p><strong>NOTICES</strong></p>
<p>All notices and communications shall be in writing, in English and shall be deemed given if delivered personally or by commercial messenger or courier service, or mailed by registered or certified mail (return receipt requested) or sent by email, with due acknowledgment or complete transmission to the following address:<br/>Postal Address: Volano Entertainment Pvt Ltd- 809 Udyog Vihar, Phase V, Gurugram (Haryana) – 122016, India<br/>Email id: info@devilscircuit.com<br/>Notices provided by personal delivery will be effective immediately. Notices provided by overnight courier will be effective 1 (one) business day after they are sent. Notices provided by registered mail will be effective 3 (three) business days after they are sent.</p>
<p><strong>COMPLAINTS AND CUSTOMER CARE</strong></p>
<p>If your complaint is about a Product, our Service, the VEPL Platform or anything else, you may reach our customer care, at the address detailed below.</p>
<p>In the unlikely event that we are unable to resolve a complaint with you directly, you have the right to refer the dispute to the Grievance Officer.<br/>By email: info@devilscircuit.com<br/>Registered Office: Volano Entertainment Pvt Ltd- 809 Udyog Vihar, Phase V, Gurugram (Haryana) – 122016, India<br/>The details of our Grievance Officer are as below:<br/>Name: Neelesh Kumar<br/>Email Address: <a href="mailto:info@devilscircuit.com">info@devilscircuit.com</a></p>
<p><strong>FORCE MAJEURE</strong></p>
<p>Notwithstanding anything contained in these General T&Cs or elsewhere, we shall not be held responsible for any delay, loss, damage or failure to comply with the obligations, if such loss, damage, delay or failure arises from any cause which is beyond our reasonable control and is attributable to a Force Majeure Event. A "Force Majeure Event" means any event that is beyond the reasonable control of VEPL and includes, without limitation, pandemic, epidemic, fire, flood, explosion, acts of God, civil commotion, strikes, lock outs or industrial action of any kind, riots, insurrection, war, acts of government, power failure, sabotage, computer hacking, unauthorised access to computer data and storage device, system failure, virus, attacks, bugs, computer crashes, breach of security and encryption.</p>
<p><strong>INTERPRETATION</strong></p>
<p>Headings, subheadings, titles, subtitles to clauses, sub-clauses and paragraphs are for information only and shall not form part of the operative provisions of these General T&Cs and shall be ignored in construing the same.</p>
<p>Words denoting the singular shall include the plural and words denoting any gender shall include all genders.</p>
<p>The words “include” and “including” are to be construed without limitation.</p>
<p><em>Last Updated: June 2024</em></p>

            </div>
    </div>
}

export default TermsAndCondition;
