import React from "react";
import "./village.css";
import { villagetext } from "../../assets/village/villagetext";
import FindRace from "../common/findRaceFooter/findrace";
import PageHead from "../common/pageHead/pageHead";
import band from "../../assets/homepage/newband.png";

const Village = () => {
  return (
    <div className="village_wrapper">
      <PageHead
        page_name="The Village"
        page_head={villagetext.villagehead}
        text_1={villagetext.villageubtext1}
        text_2={villagetext.villageubtext2}
        text_3={null}
        media={"https://spectacom-public-assets.s3.ap-south-1.amazonaws.com/dcw/12.mp4"}
        media_type="video"
      />
      <div className="band_img_div_village">
          <img src={band} alt="band" loading="lazy" />
        </div>
      {/* <div className="village-divider"></div> */}
      <div className="scroll-animations">
        {villagetext.parallaxdata.map((item) => (
          <div className="scroll-div" key={item.id}>
            <div className={item.id % 2 === 0 ? "scrolldiv1" : "scrolldivone"}>
              <div>
                <label>{item.head}</label>
                <p>{item.text}</p>
              </div>
            </div>
            <div className="scrolldiv2">
              <img src={item.img} alt="image" className="scrolldivimage" />
              {/* <h1 className="inscrolldiv2">hello</h1> */}
              <div
                className="inscrolldiv2"
                style={{ backgroundImage: `url(${item.img})` }}
              >
                <div className="contentvillage">
                  <div>
                    <label>{item.head}</label>
                    <p>{item.text}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <FindRace heading="Unleashing the Power of Resistance!" para="Resilience empowers us to conquer obstacles with unwavering determination, embodying the strength of resistance."  btn_text="Find Your Race" />

    </div>
  );
};

export default Village;
